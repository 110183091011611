import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import VehicleListTemplate from "./vehicleListTemplate";
import {DataScroller} from "primereact/datascroller";
import LoadingSpinner from "../loadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUp} from "@fortawesome/pro-regular-svg-icons";

const VehicleList = ({addChip, filteredVehicles, displayType, showFilters}) => {

    const [panelData, setPanelData] = useState([]);
    const [index, setIndex] = useState(15);
    const [isLoading, setIsLoading] = useState(false);
    const loaderRef = useRef(null);
    const [showLoadMore, setShowLoadMore] = useState(false)


    useEffect(() => {
        // setIndex(15);
        // const newData = filteredVehicles;
        setPanelData(filteredVehicles?.features)

    }, [filteredVehicles]);

    useEffect(() => {
        return () => setPanelData(null);
    }, []);

    const loadMore = async (e) => {
        // console.log(index+15)
        // const newData = JSON.parse(JSON.stringify(filteredVehicles?.features.slice(index, index+15)));
        // setIndex(index+15)
        // setPanelData((prevData) => [...prevData, ...newData])

    }



    // onScroll={async (e) => {
    //
    //                      if (showLoadMore && (e.nativeEvent.target.scrollTop / e.nativeEvent.target.scrollHeight) * 100 < 55) {
    //                          await setShowLoadMore(false)
    //
    //                      }
    //                      if (!showLoadMore && ((e.nativeEvent.target.scrollTop / e.nativeEvent.target.scrollHeight) * 100 > 55)) {
    //                          await setShowLoadMore(true)
    //                          await loadMore();
    //                      }
    //                  }}

    return (
        <div style={{height: 'calc(100vh - 210px)'}}>
            <div style={{height: '100%',overflowY: 'auto', position: 'relative'}} id="vList"

            >



                {panelData?.map((v, i) => (
                    <React.Fragment key={v?.properties?.dn}>
                        <VehicleListTemplate d={v} addChip={addChip} displayType={displayType}/>
                    </React.Fragment>
                ))}

                <div ref={loaderRef} style={{height: '90px'}} >
                    {isLoading &&
                        <div style={{ display: "flex", justifyContent: "center",height: '100px', alignItems: 'center'}}>Loading</div>
                    }
                </div>
            </div>

        </div>


    )

}

export default VehicleList
