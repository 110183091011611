import React, {useContext, useEffect, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {fetchActivityData} from "../reportFunctions/fetchActivityData";
import {ProgressSpinner} from "primereact/progressspinner";
import {formatDateOnly, formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";

import LoadingSpinner from "../../../loadingSpinner";
import ActivityReportSummary from "../activityReport/activityReportSummary";
import VehicleIdlingReportTable from "./vehicle/vehicleIdlingReportTable";
import VehicleIdlingReportSummary from "./vehicle/vehicleIdlingReportSummary";
import FleetIdlingReportSummary from "./fleet/fleetIdleReportSummary";
import FleetIdlingReportTable from "./fleet/fleetIdleReportTable";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import indexContext from "../../../../contexts/indexContext";
import ReportsParameterSelection from "../reportsParameterSelection";
import reportsContext from "../../../../contexts/reportsContext";



const IdlingReport = ({dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi}) => {

    const {vehicle, fleetsToShow, vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext)
    const {theVehicle, theFleet} = useContext(reportsContext);

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }


    const accumulator = (data, prop) => {
        return data.reduce((totalValue, item) => {
            switch (prop) {
                case 'journeys': return totalValue + item?.journeys?.length;
                case 'startTime':
                    const st = new Date(item?.firstJourney?.start?.at);
                    const stMins = (st.getHours() * 60) + st.getMinutes();
                    return  item?.firstJourney ? totalValue +  stMins : totalValue + 0;
                case 'endTime':
                    const et = new Date(item?.lastJourney?.endTime);
                    const etMins = (et.getHours() * 60) + et.getMinutes();
                    return  item?.lastJourney ? totalValue +  etMins : totalValue + 0;
                default : return totalValue + item?.[prop];
            }
        }, 0);
    }

    const generateReportData = () => {
        fetchActivityData(dateRange, theVehicle, theFleet, vehicles).then(r => {

            setActivityData(r);

            let total

            if (theVehicle){
                total = {
                    journeys: accumulator(r, 'journeys'),
                    distance : accumulator(r, 'totalDistance'),
                    driving: accumulator(r, 'totalDur'),
                    idleTime: accumulator(r, 'totalIdleTime'),
                    trueIdleTime: accumulator(r, 'totalTrueIdleTime'),
                    startTime : accumulator(r, 'startTime') * 60,
                    endTime : accumulator(r, 'endTime') * 60
                }

            } else {
                let distance = 0;
                let idle = 0;
                let journeys = 0;
                let duration = 0;
                let trueIdle = 0;


                r?.forEach(day => {
                    day.vData.forEach(v => {
                        console.log(v)
                        journeys += v.journeys?.length;
                        distance += v?.totalDistance;
                        idle += v?.totalIdleTime;
                        trueIdle += v?.totalTrueIdleTime;
                        duration += v?.totalDur;
                    });
                })


                total = {
                    journeys,
                    distance,
                    idle,
                    duration,
                    trueIdle
                }
            }

            setActTotals(total);

            const cache = {
                actData: r,
                totals: total,
                dateRange: JSON.stringify(dateRange)
            }

            if (theVehicle){
                cache.dn = theVehicle?.properties.dn
            } else {
                cache.fleet = theFleet
            }


            localStorage.setItem('idleReport', JSON.stringify(cache));
            handleReportLoading(false);
        });
    }


    useEffect(async () => {
        if (localStorage.getItem('idleReport')){

            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('idleReport'));



            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){
                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                    console.log(cache)
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }

            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
       }



    }, [dateRange, theVehicle, theFleet, generateReport]);



    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <div style={{height: '100%', width:'100%', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}
                                 className={reportToPrint === 'idling' ? "section-to-print" : ''}>
                                <div className="hideNormalShowPrint">
                                    <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div>
                                            <h1>
                                                Idling report
                                            </h1>

                                            {theVehicle &&
                                                <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                    <b>Driver:</b>&nbsp;
                                                    {(theVehicle?.properties?.driver && theVehicle?.properties.driver.length > 0) ?
                                                        <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                                        :
                                                        <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                                    }
                                                    <br />

                                                    <b>Date range:</b>&nbsp;
                                                    {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                    &nbsp;->&nbsp;
                                                    {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                                </div>
                                            }



                                        </div>
                                        <div>
                                            <MflLogo style={{fill:Colours.logoTextColour}} size='100px' textColor={'black'}/>
                                            <div  style={{marginTop: '25px',fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                <br />
                                                <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                            </div>
                                        </div>
                                    </div>

                                    {showKpi && theVehicle &&
                                        <VehicleIdlingReportSummary actTotals={actTotals} activityData={activityData}/>
                                    }

                                    {showKpi && theFleet > 0 && !theVehicle &&
                                        <FleetIdlingReportSummary actTotals={actTotals} activityData={activityData}/>
                                    }


                                </div>

                                <div className="hideOnPrint">
                                    {showKpi && theVehicle &&
                                        <VehicleIdlingReportSummary actTotals={actTotals} activityData={activityData}/>
                                    }

                                    {showKpi && theFleet > 0 && !theVehicle &&
                                        <FleetIdlingReportSummary actTotals={actTotals} activityData={activityData}/>
                                    }


                                    {theVehicle &&
                                        <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                            <b>Driver:</b>&nbsp;
                                            {(vehicle?.properties?.driver && theVehicle?.properties.driver.length > 0) ?
                                                <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                                :
                                                <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                            }
                                            <br />

                                            <b>Date range:</b>&nbsp;
                                            {formatDateTimesheetReport(activityData?.[0]?.date)}
                                            &nbsp;->&nbsp;
                                            {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                        </div>
                                    }


                                    {fleetsToShow?.size > 0 &&
                                        <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                            <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet, fleets)}
                                            <br />

                                            <b>Date range:</b>&nbsp;
                                            {formatDateTimesheetReport(activityData?.[0]?.date)}
                                            &nbsp;->&nbsp;
                                            {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                        </div>
                                    }



                                </div>

                                <div style={{overflowY: 'scroll', height: '61vh', overflowX: 'hidden'}} className="scroller">

                                    {theVehicle ?
                                        <React.Fragment>
                                            {activityData?.map(item => <VehicleIdlingReportTable data={item}/>)}
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {activityData?.map(item => <FleetIdlingReportTable data={item}/>)}
                                        </React.Fragment>
                                    }
                                </div>



                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>
            :
                <div style={{padding: '40px 10px 10px 10px',width: '100%',height: '100%',position: 'relative'}}>
                    <div style={{fontWeight: 'bold',fontSize: '18px',position: 'absolute',textAlign: 'center',
                        top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
                        Please select a vehicle and timespan

                        <br />

                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>

                    </div>
                </div>

            }
        </React.Fragment>
    )
}
export default IdlingReport

