import React, {useContext, useEffect, useState} from 'react'
import {
    formatDateForVehicles,
    formatDateOnly,
    formatDateTimesheetReport, fullDurationFromSeconds
} from "../../../../../functions/formatting/formatDate";
import ReportsParameterSelection from "../reportsParameterSelection";
import mainContext from "../../../../contexts/mainContext";
import indexContext from "../../../../contexts/indexContext";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetSpeeding from "../../../../../api/postFleetSpeeding";
import postFleetPoi from "../../../../../api/postFleetPoi";
import LoadingSpinner from "../../../loadingSpinner";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {MflLogo} from "../../../../../svgs/mflLogo";
import {Colours} from "../../../../../colours";
import SpeedingOverviewReportSummary from "../speedingOveriew/speedingOverviewReportSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faExclamationCircle, faSignsPost} from "@fortawesome/pro-regular-svg-icons";
import {faPercent, faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";


const PoiReport = ({dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi}) => {


    const {vehicle, fleetsToShow, vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext)
    const {theVehicle, theFleet} = useContext(reportsContext);

    const [generateReport, setGenerateReport] = useState(false);
    const [activityData, setActivityData]= useState();

    const handleGenerateReport = (val) => setGenerateReport(val);


    const generateReportData = async () => {
        if (new Date(dateRange[1]) - new Date(dateRange[0]) > 604800000 ) {
            alert('too long');
            return;
        }





        const data = {
            fleetId: theFleet?.fleetId,
            startTime: formatDateForVehicles(dateRange[0]),
            endTime: formatDateForVehicles(dateRange[1])
        }

        const r = await postFleetPoi(data);



        setActivityData(r);

        const cache = {
            actData: r,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }


        localStorage.setItem('speedingReportOverview', JSON.stringify(cache));
        handleReportLoading(false);

    }



    useEffect(async () => {
        if (localStorage.getItem('fleetPoiReport')){

            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('fleetPoiReport'));



            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){
                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    //setActTotals(cache.totals);
                    setActivityData(cache.actData);
                    console.log(cache)
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    //setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }

            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }



    }, [dateRange, theVehicle, theFleet, generateReport]);



    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>

                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            {activityData?.length > 0 ?
                                <div style={{
                                    height: '100%',
                                    width: '100%',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    paddingTop: '20px'
                                }}
                                     className={reportToPrint === 'speedingOverview' ? "section-to-print" : ''}>
                                    <div className="hideNormalShowPrint">
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <h1>
                                                    Fleet POI Report
                                                </h1>

                                                {theFleet &&
                                                    <div style={{
                                                        fontSize: "1.3em",
                                                        lineHeight: "25px",
                                                        padding: "20px 0px 20px 0px"
                                                    }}>
                                                        <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                        <br/>

                                                        <b>Date range:</b>&nbsp;
                                                        {formatDateTimesheetReport(dateRange?.[0])}
                                                        &nbsp;->&nbsp;
                                                        {formatDateTimesheetReport(dateRange?.[1])}
                                                    </div>
                                                }

                                            </div>
                                            <div>
                                                <MflLogo style={{fill:Colours.logoTextColour}} size='100px' textColor={'black'}/>

                                                <div style={{
                                                    marginTop: '25px',
                                                    fontSize: "1.3em",
                                                    lineHeight: "25px",
                                                    padding: "20px 0px 20px 0px"
                                                }}>
                                                    <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                    <br/>
                                                    <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                                </div>
                                            </div>
                                        </div>

                                        {/*{showKpi && theFleet && !theVehicle &&*/}
                                        {/*    <SpeedingOverviewReportSummary actTotals={actTotals} />*/}
                                        {/*}*/}


                                    </div>

                                    <div className="hideOnPrint">

                                        {/*{showKpi && theFleet &&*/}
                                        {/*    <SpeedingOverviewReportSummary actTotals={actTotals} />*/}
                                        {/*}*/}

                                        {theFleet &&
                                            <div style={{
                                                fontSize: "1.3em",
                                                lineHeight: "25px",
                                                padding: "20px 0px 20px 0px"
                                            }}>
                                                <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                <br/>

                                                <b>Date range:</b>&nbsp;
                                                {formatDateTimesheetReport(dateRange?.[0])}
                                                &nbsp;->&nbsp;
                                                {formatDateTimesheetReport(dateRange?.[1])}
                                            </div>
                                        }

                                    </div>


                                    {theFleet &&

                                        <React.Fragment>
                                            {activityData?.map((data, index) =>
                                                <div
                                                    style={{overflowY: 'scroll', height: '61vh', overflowX: 'hidden'}}
                                                    className="scroller">


                                                    <div>dn: {data?.dn}</div>


                                                    <div style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: '15% 15% 15% 15% 10% 15% 15%',
                                                        borderBottom: '1px solid var(--surface-border)',
                                                        height: '30px',
                                                        marginTop: '12px',
                                                        pageBreakInside: 'auto',
                                                        fontWeight: 'bold',
                                                        textAlign: 'center'
                                                    }} className="reportTable">

                                                        <div style={{lineHeight: '30px', paddingLeft: '10px'}}>

                                                            {/*<FontAwesomeIcon icon={faCar}/>*/}
                                                            &nbsp;Place
                                                        </div>
                                                        <div style={{lineHeight: '30px'}}>
                                                            {/*<FontAwesomeIcon icon={faSignsPost}/>*/}
                                                            &nbsp;
                                                            Category
                                                        </div>

                                                        <div style={{lineHeight: '30px'}}>
                                                            {/*<FontAwesomeIcon icon={faRuler}/>*/}
                                                            &nbsp;
                                                            Time spent
                                                        </div>
                                                    </div>


                                                    {activityData && activityData?.[index]?.poi?.map((p, index) => (
                                                        <React.Fragment>
                                                            {index > 0 &&
                                                                <div style={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: '15% 15% 15% 15% 10% 15% 15%',
                                                                    borderBottom: '1px solid var(--surface-border)',
                                                                    height: '73px',
                                                                    pageBreakInside: 'avoid',
                                                                    pageBreakAfter: 'auto',
                                                                    textAlign: 'center'
                                                                }} className="reportTable" key={p?.poiId}>
                                                                    <div style={{
                                                                        lineHeight: '73px',
                                                                        paddingLeft: '10px'
                                                                    }}>
                                                                        {p?.name}
                                                                    </div>
                                                                    <div style={{lineHeight: '73px'}}>
                                                                        {p?.cat}
                                                                    </div>
                                                                    <div style={{lineHeight: '73px'}}>
                                                                        {fullDurationFromSeconds(p?.totalSeconds)}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        </React.Fragment>


                                    }

                                </div>
                                :
                                <div style={{
                                    fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                                }}>
                                    No POI data for the given fleet for the given date range
                                </div>

                            }
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <div style={{padding: '40px 10px 10px 10px', width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{
                        fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                    }}>
                    Please select a vehicle and timespan

                        <br/>

                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>

                    </div>
                </div>

            }
        </React.Fragment>
    )
}

export default PoiReport