import React from 'react';
import {DurationFromSeconds, timeFromSeconds} from "../../../../../../functions/formatting/formatDate";
import {fetchUserLevel} from "../../../../../../functions/fetchUserLevel";

const VehicleIdlingReportSummary = ({actTotals, activityData}) => {

    console.log(actTotals)

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>

            <div className="reportTotalBubble">
                <div><b>Total<br />Journeys:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.journeys}</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total<br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.distance.toFixed(0)} miles</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total Idling <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>


                    {fetchUserLevel() === 'superuser' ?
                        <React.Fragment>
                            ( i-{actTotals?.idleTime} v ti-{actTotals?.trueIdleTime})
                            ( i-{((actTotals?.idleTime / actTotals?.driving) * 100).toFixed(0)}% v ti-
                            {((actTotals?.trueIdleTime / actTotals?.driving) * 100).toFixed(0)}%)
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {DurationFromSeconds(actTotals?.idleTime)}
                            ()
                        </React.Fragment>
                    }






                </div>
            </div>
            {/*<div className="reportTotalBubble">*/}
            {/*    <div><b>Average Journey <br />Time:</b></div>*/}
            {/*    <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.driving / actTotals?.journeys)}</div>*/}
            {/*</div>*/}
            <div className="reportTotalBubble">
                <div><b>Average <br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{(actTotals?.distance.toFixed(0)/actTotals?.journeys).toFixed(2)} miles</div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Average Idling <br />Time:</b></div>

                {fetchUserLevel() === 'superuser' ?
                    <span>( i-{actTotals?.idleTime/actTotals?.journeys} v ti-{actTotals?.trueIdleTime / actTotals?.journeys})</span>
                :
                    <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.idleTime / actTotals?.journeys)}</div>
                }

            </div>

        </div>

    )
}

export default VehicleIdlingReportSummary
