import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown} from "primereact/dropdown";
import {faChevronRight, faChevronLeft, faUserBountyHunter} from "@fortawesome/pro-light-svg-icons";
import {faInfoCircle} from "@fortawesome/pro-light-svg-icons";
import {uCaseFirst} from "../../../../functions/formatting/uCaseFirst";
import {Checkbox} from "primereact/checkbox";
import {InputSwitch} from "primereact/inputswitch";
import {Tooltip} from "primereact/tooltip";
import abovePanelContext from "../../../contexts/abovePanelContext";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {confirmPopup} from "primereact/confirmpopup";
import mainContext from "../../../contexts/mainContext";

const EventButtonFilters = ({itemsMobile, changeTab, eventParams, showTagFilters, tagsToDisplay, itemTemplate, scoreSortEvents,
                            handleDateSortEvents, handleScoreSortEvents}) => {




    const contentRef = useRef();
    const prevBtnRef = useRef();
    const nextBtnRef = useRef();

    const [backwardIsDisabledState, setBackwardIsDisabledState] = useState(true);
    const [forwardIsDisabledState, setForwardIsDisabledState] = useState(true);

    const {disableDateChange, handleEventParams} = useContext(abovePanelContext)

    const {toast} = useContext(mainContext)




    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    const [width, height] = useWindowSize();


    var updateButtonState = function updateButtonState() {
        var _contentRef$current = contentRef.current,
            scrollLeft = _contentRef$current.scrollLeft,
            scrollWidth = _contentRef$current.scrollWidth;
        var width = contentRef.current.offsetWidth;
        setBackwardIsDisabledState(scrollLeft === 0);
        setForwardIsDisabledState(width + scrollLeft >= scrollWidth);
    };


    var onScroll = function onScroll(event) {
        updateButtonState();
        event.preventDefault();
    };


    var navBackward = function navBackward() {
        var width = contentRef.current.offsetWidth - getVisibleButtonWidths();
        var pos = contentRef.current.scrollLeft - width;
        contentRef.current.scrollLeft = pos <= 0 ? 0 : pos;
    };
    var navForward = function navForward() {
        var width = contentRef.current.offsetWidth - getVisibleButtonWidths();
        var pos = contentRef.current.scrollLeft + width;
        var lastPos = contentRef.current.scrollWidth - width;
        contentRef.current.scrollLeft = pos >= lastPos ? lastPos : pos;
    };


    var getVisibleButtonWidths = function getVisibleButtonWidths() {
        return [prevBtnRef.current, nextBtnRef.current].reduce(function (acc, el) {
            return el ? acc + el?.offsetWidth : acc;
        }, 0);
    };

    useEffect(() => {
        updateButtonState();
    }, [showTagFilters, width]);

    const SuperUserTagSearch = ({accept}) => {
        const [superTags, setSuperTags] = useState('')

        return (
            <React.Fragment>
                <div className="p-inputgroup" style={{width: '200px'}}>
                    <InputText type="text" placeholder="Tags" value={superTags}
                               onInput={(e) => {
                                   setSuperTags(e?.target?.value)
                               }}/>
                    <Button icon="pi pi-search" className="p-button" onClick={() => {
                        handleEventParams([superTags, 'superuserQry']);
                        accept(superTags);
                    }} />
                </div>
            </React.Fragment>
        )
    };


    const showTemplate = (event) => {
        confirmPopup({
            target: event.currentTarget,
            group: 'templating',
            header: 'Confirmation',
            message: (
                <SuperUserTagSearch  accept={accept}/>
            ),
            footer: (<div></div>)
        });
    };

    const accept = (superTags) => {
        toast.current.show({ severity: 'info', summary: 'Confirmed', detail: `showing '${superTags}' events`, life: 300000 });
    };




    return (
        <div className={"p-tabview-nav-content"} >
            {!backwardIsDisabledState &&
                <button ref={prevBtnRef} className={"p-tabview-nav-prev p-tabview-nav-btn"} onClick={navBackward}
                        style={{backgroundColor: 'var(--surface-card)', border: 'none', height: '37.8px', marginTop: '2px'}}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
            }

            <div className={"p-tabview-nav-content"} ref={contentRef} onScroll={onScroll}>


                <ul className={"p-tabview-nav"} >


                    {showTagFilters ?
                        <React.Fragment>
                            {tagsToDisplay &&
                                <React.Fragment>

                                    <li style={{display: 'flex',alignItems: 'center'}}>Event filters:</li>
                                    {Object?.keys(tagsToDisplay)?.map(key => (

                                        <React.Fragment>
                                            {tagsToDisplay?.[key]?.length > 0 &&
                                                <li style={{padding: '0px 2px'}}>
                                                    <Dropdown
                                                        options={tagsToDisplay?.[key]?.sort((a, b) => a._id.localeCompare(b._id))}
                                                        placeholder={uCaseFirst(key.replace(/([a-z])([A-Z])/g, '$1 $2'))}
                                                        optionLabel={"_id"} itemTemplate={itemTemplate}
                                                        panelClassName={"eventsDropdownPanel"}
                                                        style={{marginRight: '5px'}}
                                                        autoClose="inside"/>
                                                </li>
                                            }
                                        </React.Fragment>
                                    ))}

                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {itemsMobile.map(item => (
                                <li style={{padding: '2px 2px'}} key={item.index}>
                                    <button className={"p-button p-button-sm eventFilterButton"}
                                            style={{
                                                background: (item.display === 'All'  && eventParams?.length < 1) ? 'var(--v-list-button-selected)' :
                                                    eventParams?.includes(item.value[0]) ? 'var(--v-list-button-selected)' : 'var(--v-list-button)',
                                                color: (item.display === 'All' && eventParams?.length < 1) ? 'var(--v-list-button-text-selected)' :
                                                    eventParams?.includes(item.value[0]) ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                                                flex: 'none'
                                            }}
                                            disabled={disableDateChange}
                                            onClick={() => changeTab({index: item.index, value: item})}>
                                        <span className="p-button-label"
                                              style={item?.display ? {fontWeight:500} : {padding: '0px 10px 0px 10px', fontWeight:500}}>

                                            {item?.icon ?
                                                <FontAwesomeIcon icon={item.icon}/>
                                            :
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 5 25"
                                                     style={{height: '12px', width: '35px', verticalAlign: 'middle'}}
                                                >
                                                    <path style={{stroke: eventParams?.includes(item.value[0]) ? 'var(--v-list-button-text-selected)' :
                                                                  'var(--v-list-button-text)', strokeWidth: 4}}
                                                          d={"M17.0012 4.0014C13.5072 11.1532 6.7558 16-1 16M-1 16C-8.7559 16-15.5074 11.1532-19.0005 4.0004M-1 16-1 22M13.8436 8.8436 17.9998 13M7.4608 13.9374 10 19M-15.8438 8.8436-20 13M-9.4609 13.9374-12 19"} />
                                                </svg>
                                            }


                                            <br/>{item.display}
                                        </span>
                                    </button>
                                </li>
                            ))}


                            {fetchUserLevel() === 'superuser' &&
                                <li>
                                    <li style={{padding: '2px 2px'}}>
                                        <button className={"p-button p-button-sm eventFilterButton"}
                                                style={{
                                                    background: 'var(--v-list-button)',color: 'var(--v-list-button-text)',
                                                    flex: 'none'
                                                }}
                                                disabled={disableDateChange}
                                                onClick={showTemplate}>
                                        <span className="p-button-label"
                                              style={{
                                                  padding: '0px 10px 0px 10px',
                                                  fontWeight: 500
                                              }}>
                                            <FontAwesomeIcon icon={faUserBountyHunter} />
                                            <br/>Super
                                        </span>
                                        </button>
                                    </li>
                                </li>


                            }


                        </React.Fragment>
                    }


                </ul>
            </div>

            {!forwardIsDisabledState &&
                <button ref={nextBtnRef} className={"p-tabview-nav-next p-tabview-nav-btn"} onClick={navForward}
                        style={{
                            backgroundColor: 'var(--surface-card)',
                            border: 'none',
                            height: '37.8px',
                            marginTop: '2px'
                        }}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </button>
            }


        </div>


    )
}
export default EventButtonFilters