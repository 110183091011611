import React, {useContext, useEffect, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {fetchActivityData} from "../reportFunctions/fetchActivityData";
import ActivityReportTable from "./activityReportTable";
import {formatDateOnly, formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";
import ActivityReportSummary from "./activityReportSummary";
import LoadingSpinner from "../../../loadingSpinner";
import reportsContext from "../../../../contexts/reportsContext";
import ReportsParameterSelection from "../reportsParameterSelection";



const ActivityReport = ({}) => {

    const {dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi, theVehicle} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }

    const accumulator = (data, prop) => {
        return data.reduce((totalValue, item) => {
            switch (prop) {
                case 'journeys': return totalValue + item?.journeys?.length;
                case 'startTime':
                    const st = new Date(item?.firstJourney?.start?.at);
                    const stMins = (st.getHours() * 60) + st.getMinutes();
                    return  item?.firstJourney ? totalValue +  stMins : totalValue + 0;
                case 'endTime':
                    const et = new Date(item?.lastJourney?.endTime);
                    const etMins = (et.getHours() * 60) + et.getMinutes();
                    return  item?.lastJourney ? totalValue +  etMins : totalValue + 0;
                default : return totalValue + item?.[prop];
            }
        }, 0);
    }

    const generateReportData = () => {
        fetchActivityData(dateRange, theVehicle).then(r => {
            setActivityData(r);

            const total = {
                journeys: accumulator(r, 'journeys'),
                distance : accumulator(r, 'totalDistance'),
                driving: accumulator(r, 'totalDur'),
                stopped: accumulator(r, 'totalStopped'),
                startTime : accumulator(r, 'startTime') * 60,
                endTime : accumulator(r, 'endTime') * 60
            }
            setActTotals(total);

            const cache = {
                actData: r,
                totals: total,
                dn: theVehicle?.properties.dn,
                dateRange: JSON.stringify(dateRange)
            }

            localStorage.setItem('activityReport', JSON.stringify(cache));
            handleReportLoading(false);
        });
    }

    useEffect(async () => {

        if (localStorage.getItem('activityReport')){
            handleReportLoading(false);

            const cache = JSON.parse(localStorage.getItem('activityReport'));

            if (cache.dateRange === JSON.stringify(dateRange) && cache.dn === theVehicle?.properties?.dn){
                setActivityData(cache.actData);
                setActTotals(cache.totals);
            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && theVehicle && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }


    }, [dateRange, theVehicle, generateReport]);





    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <div style={{height: '100%', width:'100%', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}
                                 className={reportToPrint === 'activity' ? "section-to-print" : ''}>
                                <div className="hideNormalShowPrint">
                                    <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div>
                                            <h1>
                                                Activity report
                                            </h1>
                                            <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                <b>Driver:</b>&nbsp;
                                                {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                                    <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                                    :
                                                    <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                                }
                                                <br />

                                                <b>Date range:</b>&nbsp;
                                                {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                &nbsp;->&nbsp;
                                                {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                            </div>

                                        </div>
                                        <div>
                                            <MflLogo style={{fill:Colours.logoTextColour}} size='100px' textColor={'black'}/>
                                            <div  style={{marginTop: '25px',fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                <br />
                                                <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                            </div>
                                        </div>
                                    </div>

                                    {showKpi &&
                                        <ActivityReportSummary actTotals={actTotals} activityData={activityData}/>
                                    }


                                </div>

                                <div className="hideOnPrint">
                                    {showKpi &&
                                        <ActivityReportSummary actTotals={actTotals} activityData={activityData}/>
                                    }
                                    <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                        <b>Date range:</b>&nbsp;
                                        {formatDateTimesheetReport(activityData?.[0]?.date)}
                                        &nbsp;->&nbsp;
                                        {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                        <br />
                                        <b>Driver:</b>&nbsp;
                                        {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                            <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                            :
                                            <span>No driver assigned ({theVehicle.properties.registration})</span>
                                        }
                                    </div>



                                </div>

                                <div style={{overflowY: 'scroll', height: '61vh', overflowX: 'hidden'}} className="scroller">
                                    {activityData?.map(item =>  <ActivityReportTable data={item} />) }
                                </div>



                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <div style={{padding: '40px 10px 10px 10px',width: '100%',height: '100%',position: 'relative'}}>
                    <div style={{fontWeight: 'bold',fontSize: '18px',position: 'absolute',textAlign: 'center',
                        top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
                        Please select a vehicle and timespan


                        <br />


                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>



                    </div>
                </div>

            }
        </React.Fragment>
    )
}
export default ActivityReport

