import React, {useContext, useEffect, useState} from 'react';
import FleetsToShowDisplay from "./FleetsToShowDisplay";
import indexContext from "../../contexts/indexContext";
import mainContext from "../../contexts/mainContext";


const PanelHeader = ({pageToShow, date, disableDateChange, handleDate}) => {

    const Title = {
        fontWeight: 'bold',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center'
    }

    const TitleContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '5px',
        borderBottom: 'solid 1px var(--surface-border)',
        borderLeft: 'none',
        height: '46px',
        background: 'var(--surface-ground)'
    }
    const {winWidth, fleets} = useContext(indexContext)
    const {vehicleFilterParams} = useContext(mainContext)
    const [panelName, setPanelName] = useState();

    useEffect(() => {
        if (pageToShow){
            switch(pageToShow){
                case '/events': setPanelName('Events'); break;
                case '/media': setPanelName('Uploads'); break;
                case '/notifications': setPanelName('Alerts'); break;
            }
        }
    }, [pageToShow])

    return (
        <div style={TitleContainer}>
            <div style={Title}>
                {vehicleFilterParams?.fleetsToShow.size > 0 && winWidth > 800 &&
                    <FleetsToShowDisplay fleetsToShow={vehicleFilterParams?.fleetsToShow} type={panelName}/>
                }
                {(vehicleFilterParams?.fleetsToShow.size < 1 || winWidth < 800 || !vehicleFilterParams?.fleetsToShow)  &&
                    <span style={{marginLeft: '10px'}}>
                        {panelName}
                        {pageToShow === '/events' && winWidth > 800 &&
                            <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                                &nbsp;(select vehicle and date to filter)
                            </span>
                        }

                        {pageToShow === '/media' &&
                            <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                                &nbsp;(showing latest uploads)
                            </span>
                        }
                    </span>
                }
            </div>
            {/*{pageToShow !== '/notifications' && pageToShow !== '/media' &&*/}
            {/*    <CalendarDatePicker date={date} handleDate={handleDate} disableDateChange={disableDateChange}/>*/}
            {/*}*/}
        </div>
    )
}

export default PanelHeader
