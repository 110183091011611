import React from 'react';

export const MflLogo = ({size, textColor = 'black', iconColor = 'rgb(115, 24, 236)'}) => {
        return (
            <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.92 85.85"
                 style={{width: size}}  onClick={() => window.location.reload()}>
                <g id="Layer_2-2" data-name="Layer_2">
                    <g id="Layer_1-2">
                        <g>
                            <g>
                                <g>
                                    <path style={{fill: textColor}} d="M126.92.06v5.74h-14.78v9.09h11.33v5.64h-11.33v14.98h-7.11V.06h21.89,0Z"/>
                                    <path style={{fill: textColor}} d="M140.89,0v35.51h-7.11V0h7.11Z"/>
                                    <path style={{fill: textColor}} d="M175.68,23.57h-20.57c.17,2.03.88,3.63,2.13,4.78,1.25,1.15,2.79,1.73,4.62,1.73,2.64,0,4.52-1.13,5.64-3.4h7.67c-.81,2.71-2.37,4.93-4.67,6.68-2.3,1.75-5.13,2.62-8.48,2.62-2.71,0-5.14-.6-7.29-1.8-2.15-1.2-3.82-2.9-5.03-5.1s-1.8-4.74-1.8-7.62.59-5.47,1.78-7.67c1.19-2.2,2.84-3.89,4.98-5.08s4.59-1.78,7.36-1.78,5.07.58,7.19,1.73c2.11,1.15,3.76,2.79,4.93,4.9,1.17,2.11,1.75,4.54,1.75,7.29,0,1.02-.07,1.93-.2,2.74h0ZM168.52,18.8c-.04-1.83-.69-3.29-1.98-4.39-1.29-1.1-2.86-1.65-4.72-1.65-1.76,0-3.24.53-4.44,1.6s-1.94,2.55-2.21,4.44h13.36,0Z"/>
                                    <path style={{fill: textColor}} d="M208.85,23.57h-20.57c.17,2.03.88,3.63,2.13,4.78,1.25,1.15,2.79,1.73,4.62,1.73,2.64,0,4.52-1.13,5.64-3.4h7.67c-.81,2.71-2.37,4.93-4.67,6.68-2.3,1.75-5.13,2.62-8.48,2.62-2.71,0-5.14-.6-7.29-1.8-2.15-1.2-3.82-2.9-5.03-5.1s-1.8-4.74-1.8-7.62.59-5.47,1.78-7.67,2.84-3.89,4.98-5.08,4.59-1.78,7.36-1.78,5.07.58,7.19,1.73c2.11,1.15,3.76,2.79,4.93,4.9,1.17,2.11,1.75,4.54,1.75,7.29,0,1.02-.07,1.93-.2,2.74h0ZM201.69,18.8c-.04-1.83-.69-3.29-1.98-4.39-1.29-1.1-2.86-1.65-4.72-1.65-1.76,0-3.24.53-4.44,1.6s-1.94,2.55-2.21,4.44h13.36,0Z"/>
                                    <path style={{fill: textColor}} d="M126.92,49.87v5.74h-14.78v9.09h11.33v5.64h-11.33v14.98h-7.11v-35.45h21.89,0Z"/>
                                    <path style={{fill: textColor}} d="M136.99,83.98c-2.17-1.2-3.87-2.9-5.1-5.1-1.24-2.2-1.85-4.74-1.85-7.62s.64-5.42,1.9-7.62c1.27-2.2,3.01-3.9,5.21-5.1s4.65-1.8,7.36-1.8,5.16.6,7.36,1.8,3.94,2.9,5.21,5.1c1.27,2.2,1.9,4.74,1.9,7.62s-.65,5.42-1.96,7.62-3.07,3.9-5.28,5.1c-2.22,1.2-4.7,1.8-7.44,1.8s-5.15-.6-7.32-1.8h0ZM147.94,78.65c1.13-.62,2.04-1.56,2.72-2.82.68-1.25,1.02-2.77,1.02-4.57,0-2.67-.7-4.73-2.11-6.17-1.41-1.44-3.12-2.16-5.16-2.16s-3.73.72-5.1,2.16c-1.37,1.44-2.06,3.5-2.06,6.17s.67,4.73,2.01,6.17c1.34,1.44,3.02,2.16,5.06,2.16,1.28,0,2.5-.32,3.63-.94h0Z"/>
                                    <path style={{fill: textColor}} d="M165.78,63.61c1.19-2.18,2.83-3.88,4.93-5.08,2.1-1.2,4.5-1.8,7.21-1.8,3.49,0,6.38.87,8.66,2.62,2.28,1.75,3.82,4.19,4.6,7.34h-7.67c-.41-1.22-1.09-2.18-2.06-2.87-.96-.69-2.16-1.04-3.58-1.04-2.03,0-3.64.74-4.82,2.21s-1.78,3.56-1.78,6.27.59,4.75,1.78,6.22c1.19,1.47,2.79,2.21,4.82,2.21,2.88,0,4.76-1.28,5.64-3.86h7.67c-.78,3.05-2.32,5.47-4.62,7.26-2.3,1.79-5.18,2.69-8.64,2.69-2.71,0-5.12-.6-7.21-1.8-2.1-1.2-3.74-2.9-4.93-5.08-1.19-2.18-1.78-4.73-1.78-7.64s.59-5.46,1.78-7.64h0Z"/>
                                    <path style={{fill: textColor}} d="M223.05,57.19v28.14h-7.16v-3.56c-.92,1.22-2.11,2.18-3.58,2.87-1.47.69-3.07,1.04-4.8,1.04-2.2,0-4.15-.47-5.84-1.4s-3.02-2.3-3.99-4.11c-.96-1.81-1.45-3.97-1.45-6.48v-16.51h7.11v15.49c0,2.24.56,3.95,1.68,5.16s2.64,1.8,4.57,1.8,3.5-.6,4.62-1.8c1.12-1.2,1.68-2.92,1.68-5.16v-15.49h7.16,0Z"/>
                                    <path style={{fill: textColor}} d="M234.66,84.54c-1.83-.83-3.28-1.96-4.34-3.38-1.07-1.42-1.65-3-1.75-4.72h7.16c.13,1.09.67,1.98,1.6,2.69.93.71,2.09,1.07,3.48,1.07s2.41-.27,3.18-.81c.76-.54,1.14-1.24,1.14-2.08,0-.92-.47-1.6-1.4-2.06-.93-.46-2.41-.96-4.44-1.5-2.1-.51-3.82-1.03-5.16-1.58-1.34-.54-2.49-1.37-3.46-2.49-.96-1.12-1.45-2.62-1.45-4.52,0-1.56.45-2.98,1.34-4.27.9-1.28,2.18-2.3,3.86-3.05s3.65-1.12,5.92-1.12c3.35,0,6.03.84,8.02,2.52,2,1.68,3.1,3.94,3.3,6.78h-6.81c-.1-1.12-.57-2.01-1.4-2.67s-1.94-.99-3.33-.99c-1.28,0-2.28.24-2.97.71s-1.04,1.13-1.04,1.98c0,.95.47,1.67,1.42,2.16.95.49,2.42.99,4.42,1.5,2.03.51,3.71,1.03,5.03,1.58,1.32.54,2.46,1.38,3.43,2.52.96,1.13,1.47,2.63,1.5,4.5,0,1.62-.45,3.08-1.34,4.37-.9,1.28-2.18,2.29-3.86,3.02s-3.63,1.09-5.87,1.09-4.37-.41-6.2-1.24h0Z"/>
                                </g>
                                <g>
                                    <polygon style={{fill: iconColor}} points="8.18 31.25 0 31.25 0 .01 31.24 .01 31.24 8.19 8.18 8.19 8.18 31.25"/>
                                    <polygon style={{fill: iconColor}} points="31.24 85.85 0 85.85 0 54.61 8.18 54.61 8.18 77.68 31.24 77.68 31.24 85.85"/>
                                    <polygon style={{fill: iconColor}} points="85.84 85.85 54.6 85.85 54.6 77.68 77.66 77.68 77.66 54.61 85.84 54.61 85.84 85.85"/>
                                    <polygon style={{fill: iconColor}} points="85.84 31.25 77.66 31.25 77.66 8.19 54.6 8.19 54.6 .01 85.84 .01 85.84 31.25"/>
                                    <path style={{fill: iconColor}} d="M56.42,42.93c0-7.45-6.06-13.5-13.5-13.5s-13.5,6.06-13.5,13.5,6.06,13.5,13.5,13.5,13.5-6.06,13.5-13.5Z"/>
                                </g>
                            </g>
                            <path style={{fill: textColor}} d="M230.63,13.21v-5.84h-6.3V0h-7.16v7.38h-3.35v5.84h3.35v13.56c0,5.82,3,8.74,8.99,8.74h4.47v-5.99h-3.3c-1.09,0-1.85-.21-2.31-.64-.46-.42-.68-1.11-.68-2.06v-13.61h6.3,0Z"/>
                        </g>
                    </g>
                </g>
            </svg>
        )
        }

export default MflLogo
