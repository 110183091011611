import React, {useContext} from 'react';

import {friendlyFleetName} from "../../../functions/formatting/friendlyFleetName";
import indexContext from "../../contexts/indexContext";
import mainContext from "../../contexts/mainContext";


const FleetsToShowDisplay = ({type, fleetsToShow}) => {

    const {fleets} = useContext(indexContext)

    return (
        <span style={{marginLeft: '10px'}}>{type} for&nbsp;
            {Array.from(fleetsToShow).map((fleet, index) =>
                <span key={index}>

                    {fleetsToShow?.size > 1 && index > 0 && index < fleetsToShow?.size - 1 &&
                        <span>,&nbsp;</span>
                    }

                    {fleetsToShow?.size > 1 && index > 0 && index === fleetsToShow?.size - 1 &&
                        <span>&nbsp;&&nbsp;</span>
                    }


                    {friendlyFleetName(fleet, fleets)}

                </span>
            )}
        </span>
    )
}


export default FleetsToShowDisplay
