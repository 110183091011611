import React, {useContext, useEffect, useRef, useState} from 'react';
import fetchVideo from "../../../../../api/media/fetchVideo";
import {Slider} from "primereact/slider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload, faGauge, faGaugeLow, faGaugeMax,
    faPause,
    faPlay,
    faUpRightAndDownLeftFromCenter
} from "@fortawesome/pro-regular-svg-icons";
import {Dropdown} from "primereact/dropdown";
import {formatDate, splitStrTime} from "../../../../../functions/formatting/formatDate";
import {API_URL, NOTIFICATIONS_CALLBACK_URL} from "../../../../../Constants";
import postVideoRequest from "../../../../../api/media/postVideoRequest";
import mainContext from "../../../../contexts/mainContext";
import mflLoading from "../../../../../svgs/mfl_waiting.gif";
import MultiVideoUploadProgressTracker from "../../../requestingMedia/multiVideoUploadProgressTracker";
import {
    faArrowDownLeftAndArrowUpRightToCenter,
    faGaugeMin,
    faRectangle,
    faVideo
} from "@fortawesome/pro-light-svg-icons";
import {Tooltip} from "primereact/tooltip";
import {selectedOptionTemplate} from "../../../../../functions/templates/selectedOptionTemplate";
import {optionTemplate} from "../../../../../functions/templates/optionTemplate";
import FetchMediaToken from "../../../../../api/media/fetchMediaToken";
import {Message} from "primereact/message";
import {ConfirmPopup} from "primereact/confirmpopup";
import {maxSpeed} from "../../../../../functions/formatting/maxspeed";
import {fetchBlockedEndpoints} from "../../../../../functions/fetchBlockedEndpoints";
import indexContext from "../../../../contexts/indexContext";

const MultiVideoV2 = ({event, camPosArray, budget, deviceDetails, videos, addVideo, videosUploaded, handleView,
                          handleEventModalWidth, handleSpeed, handleSpeedLimit}) => {


    //UPLOADS TO PERSIST ACROSS TAB CHANGES
    const {winWidth} = useContext(indexContext)
    const {vehicles, refreshEvent} = useContext(mainContext);
    const videosRef = useRef({});
    const videoParentRef = useRef({});
    const parentRef = useRef();
    const [playing, setPlaying] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [requestedUploads, setRequestedUploads] = useState([]);
    const [toLoad, setToLoad] = useState(new Set(videos.concat(videosUploaded)));
    const [fullscreen, setFullscreen] = useState(false);

    const [options, setOptions] = useState([]);
    const [camsAvailable, setCamsAvailable] = useState([]);
    const [requested, setRequested] = useState();
    const [showConfirm, setShowConfirm] = useState(false);

    const chnClicked = useRef()





    const videoSize = useRef();

    const order = ["Front", "Driver", "Rear", "Nearside", "Offside", "Load"];

    const speedOptions = [
        {display: '0.25x Speed', val: 0.25},
        {display: '0.50x Speed', val: 0.5},
        {display: '0.75x Speed', val: 0.75},
        {display: '1.00x Speed', val: 1},
        {display: '2.00x Speed', val: 2.0},
    ]


    const getFile = (ch) =>{
        const parent = event?.videos?.filter(vid => vid.ch == ch);
        const parent2 = videosUploaded?.filter(vid => vid.ch == ch.toString());

        if (parent?.length > 0 ){
            return parent[0].file
        } else if (parent2?.length > 0){
            return parent2[0].filename
        } else {
            return null
        }
    }


    const getStatus = (ch) =>{
        const parent = event?.videos?.filter(vid => vid.ch == ch);
        const parent2 = videosUploaded?.filter(vid => vid.ch == ch.toString());
        if (parent?.length > 0 ){
            return parent[0].status
        } else if (parent2?.length > 0){
            return parent2[0].status
        } else {
            return null
        }
    }



    const playMedia = () => {
        videos?.forEach(item => {
            videosRef?.current[item]?.play();
        });
    }

    const pauseMedia = () => {
        videos?.forEach(item => {
            videosRef?.current[item]?.pause();

        });
    }

    const playPauseMedia = () => {




        // if (playing){
        //     setPlaying(false);
        //     videos?.forEach(item => {
        //         videosRef.current[item].pause();
        //
        //     });
        // } else {
        //     setPlaying(true);
        //     videos?.forEach(item => {
        //         videosRef.current[item].play();
        //     });
        // }
    }


    const handleScrubMouseMove = (percentageWidth) => {

        videos.forEach(item => {
            videosRef.current[item].currentTime = videosRef.current[item].duration * (percentageWidth.value/100);
        });

        const firstVid = Object.keys(videosRef.current);
        const ref = firstVid[0];
        const vid = videosRef.current[ref];
        setPercentage((vid.currentTime / vid.duration ) * 100)
    }

    const str_pad_left = (num, size) =>{
        let s = "000000000" + num;
        return s.substr(s.length - size);
    }


    const handleChnClicked = (val) => {
        chnClicked.current = val
    }


    const mediaSeeked = (event) => {


        videos.forEach(item => {
            console.log(item)
            if (videosRef.current[item].currentTime !== event.target.currentTime && videosRef.current[item].paused) {
                videosRef.current[item].currentTime = event.target.currentTime;
            }
        });


      // console.log(chnClicked.current)
      //  //
      //  const keys = Object.keys(videosRef?.current);
      //
      //
      //   keys?.forEach(key => {
      //       if (key !== chnClicked.current){
      //           console.log(key)
      //           videosRef.current[key].currentTime = e.target.currentTime;
      //       }
      //   });
    }


    const mediaLoaded = () => {

    }

    const downloadFile = (val) => {

        const video = getFile(val.channel)

        const splitStr = video.split('_');
        const times = splitStr[1].split("-");

        const vehicle = vehicles?.features.filter(v => v?.properties?.dn?.toString() === splitStr[0])[0];
        let camPos;

        event.vehicleDetails[0].cameras.forEach(cam => {
            if (cam.channel === val.channel){
                camPos = cam.camPosition
            }
        })

        const str = vehicle.properties.registration + " " +  splitStrTime(times[0]) + " " + camPos;

        fetchVideo(video).then(r => {
            const urlObj = URL.createObjectURL(r);

            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.target = '_blank'
            a.href = urlObj;
            a.download = str;
            a.click();
        })





    }

    const checkDisabled = (val) => {
        return !getFile(val.channel)
    }

    const timeFromFile = (str) => {
        return str.substr(0,4) + "-" + str.substr(4,2) + "-" + str.substr(6,2)
            + " " + str.substr(8,2) + ":" + str.substr(10,2) + ":" + str.substr(12,2)
    }



    const handleUploadEventVid =  (v) => {

        const eventVidTimeStr = event?.videos?.[0]?.file.split("_")[1];
        const eventVidTimes = eventVidTimeStr.split("-");
        const uploads = requestedUploads.slice();

        const obj = {
            eventId: event._id,
            dn: event?.dn,
            ch: v,
            st: formatDate(new Date(timeFromFile(eventVidTimes[0])).getTime()),
            et: formatDate(new Date(timeFromFile(eventVidTimes[1])).getTime()),
            callback: NOTIFICATIONS_CALLBACK_URL
        }

        postVideoRequest(obj, deviceDetails).then(r =>  {
            uploads[v.toString()] = r
        });

        setTimeout(() => {
            setCamsToReq(new Set([]));
            setRequestedUploads(uploads);
        }, 500);

        setTimeout(() => {
            refreshEvent(event)
        }, 500);



    }

    const toggleFullscreen = () => {

        if (!fullscreen){
            parentRef?.current?.requestFullscreen();
        } else {
            document?.exitFullscreen();
        }

        setFullscreen(!fullscreen);
    }



    const handleFsChange = (e) => {
        if (document.fullscreenElement || document.webkitFullscreenElement ||
            document.mozFullScreenElement) {
            setFullscreen(true);
            //videoSize.current = parseInt(videoSize?.current?.slice(0, -1) - 5 )+ '%';
        } else {
            setFullscreen(false)
            //videoSize.current = parseInt(videoSize?.current?.slice(0, -1) - 5 )+ '%';
        }
    }

    const videoLoaded = (video) => {

        toLoad.delete(video.toString());
        setToLoad(new Set([...toLoad]));
    }



    const handleMediaEnded = () => {
        // 1s delay to account for differences in videos durations
        setTimeout(() => {
            setPlaying(false);
            setPercentage(0);
        }, 1000)

    }


    const changeSpeed = (e) => {

        videos?.forEach(item => {
            videosRef.current[item].playbackRate = e.value.val;
        });
    }

    const speedOptionTemplate = (option, selected) => {

        let icon = faGauge;

        switch (option.val){
            case 0.25: icon = faGaugeMin; break;
            case 0.5: icon = faGaugeLow; break;
            case 0.75: icon = faGaugeLow; break;
            case 2.0: icon = faGaugeMax; break;
        }

        return (
            <div>
                <FontAwesomeIcon icon={icon} />
                {!selected &&
                    <React.Fragment>
                        &nbsp;{option.display}
                    </React.Fragment>
                }
            </div>
        );
    }

    const selectedSpeedOptionTemplate = (option) => {
        if (option) {
            return speedOptionTemplate(option, true)
        }
    }

    useEffect(() => {
        if (deviceDetails){

            const optionsRaw = [];
            deviceDetails?.cameras.forEach(cam => {
                optionsRaw.push({
                    display: cam.camPosition,
                    channel: cam.channel
                })
            });

            setOptions(optionsRaw)
        }
    }, [deviceDetails])

    useEffect( async () => {
        const newToLoad = new Set([]);

        for (const video of videos) {

            if (videosRef?.current?.[video]){
                if (getStatus(video) == 'uploaded'){
                    const file = await getFile(video);
                    const token = await FetchMediaToken(file);
                    videosRef.current[video].src = API_URL + '/view-video/' + file + '?mt=' + token?.access_token;
                    await new Promise(r => setTimeout(r, 100));
                    videoLoaded(video);
                } else {
                    newToLoad.add(video);
                }
            }


           setToLoad(newToLoad)
        }
    }, [videos])

    useEffect(() => {
        if(videos && requestedUploads){
            const filtered = requestedUploads.filter(function (el) {
                return el != null;
            });

            const requested = filtered.reduce((acc, curVal) => acc.concat(curVal.ch), [])
            const theVids = [...new Set(videos.concat(requested))]

            if (theVids?.length > 4){
                handleEventModalWidth('70vw')
            }
            const uploadedChannels = theVids.reduce((acc, curVal) => acc.concat(curVal), []);
            setCamsAvailable(deviceDetails?.cameras.filter(cam => !uploadedChannels.includes(cam.channel)));
            let amount  = theVids.length < 2 ? 1.1 : theVids.length > 2 ? Math.ceil(theVids.length / 2) : 2

            videoSize.current = `${(100 / amount)}%`;



        }
    }, [videos, requestedUploads])

    useEffect(() => {
        handleSpeed((event?.eventData?.[0]?.speed / 100 * 0.621371).toFixed())
        handleSpeedLimit(event?.eventData?.[0]?.maxspeed)

        document.addEventListener('fullscreenchange', handleFsChange);

        return () => {
            if (videosRef.current?.length > 0){
                videosRef?.current?.forEach(ele => {
                    URL.revokeObjectURL(ele.src)
                })
            }


            document.removeEventListener('fullscreenchange', handleFsChange);
        }
    }, []);



    const ConfirmFooter = () => {
        return (
            <div style={{display: 'flex', padding: '0px 1.5rem 1.5rem 1.5rem', justifyContent: 'space-between'}}>
                <button className="p-button p-button-sm p-button-secondary" onClick={() => setShowConfirm(false)}>
                    Close
                </button>
            </div>
        )
    }

    return (
        <div style={{textAlign:'center'}}  ref={parentRef}>

            {!fullscreen &&
                <div style={{flex: 1, textAlign: 'right', color: 'var(--text-color)', alignItems: 'center', display: 'flex', justifyContent:'flex-end', gap: '5px'}}>


                    <FontAwesomeIcon icon={!fullscreen ? faUpRightAndDownLeftFromCenter : faArrowDownLeftAndArrowUpRightToCenter}
                                     className="p-button-label fullScreenTooltip" onClick={() => toggleFullscreen(!fullscreen)}
                                     style={{cursor: 'pointer', fontSize: '18px', flex:0}}  data-pr-position="bottom"/>

                    <Tooltip target={".fullScreenTooltip"}>Fullscreen</Tooltip>



                    {winWidth > 800 &&
                        <FontAwesomeIcon icon={faRectangle} onClick={() => handleView()}
                                         style={{cursor: 'pointer', fontSize: '22px', flex:0}}
                                         className={"multiVidTooltip"} data-pr-position="bottom"/>

                    }

                    <Tooltip target={".multiVidTooltip"}>Single Video playback</Tooltip>





                </div>
            }

            <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                {deviceDetails && deviceDetails?.cameras?.sort((a, b) => order.indexOf(a.camPosition) - order.indexOf(b.camPosition)).map((cam, i) =>
                    <React.Fragment key={cam.channel}>
                        {getFile(cam.channel) ?
                            <div style={{flex: `0 0 ${videoSize?.current}`, position:'relative', marginTop: '5px'}}>
                                <div style={{marginBottom: '5px'}}>
                                    <FontAwesomeIcon icon={faVideo} style={{marginRight: '8px', color: 'var(--text-color)'}}/>
                                    {cam.camPosition}
                                </div>
                                <div ref={ref => videoParentRef.current[cam.channel] = ref}>


                                    {event?.videos?.filter(vid => vid.ch === cam.channel)[0]?.status !== 'uploaded' ?
                                        <MultiVideoUploadProgressTracker
                                            upload={event?.videos?.filter(vid => vid.ch === cam.channel)[0]}
                                            vehicles={vehicles}
                                            ref={ref => videosRef.current[cam.channel] = ref} addVideo={addVideo}
                                            mediaLoaded={mediaLoaded} handleMediaEnded={handleMediaEnded}
                                            videoLoaded={videoLoaded}
                                            cam={cam} pauseMedia={pauseMedia} playMedia={playMedia}
                                            mediaSeeked={mediaSeeked}
                                            handleChnClicked={handleChnClicked}/>

                                    :
                                        <video style={{width: '96%'}} src={''} controls={true} ref={ref => videosRef.current[cam.channel] = ref}
                                               onLoadedMetadata={() => mediaLoaded()} onPlay={playMedia}
                                               onPause={pauseMedia}
                                               onCanPlayThrough={() => videoLoaded(cam.channel)}
                                               onEnded={() => handleMediaEnded()}
                                               onSeeked={(e) => mediaSeeked(e, cam)}
                                               onSeeking={(e) => handleChnClicked(cam.channel)}
                                        />


                                    }


                                </div>
                            </div>
                            :
                            <React.Fragment>
                                {requestedUploads?.[cam.channel.toString()] &&
                                    <div style={{
                                        flex: `0 0 ${videoSize?.current}`,
                                        marginTop: '5px',
                                        maxWidth: '49%',
                                        position: 'relative'
                                    }}>
                                        <div style={{ marginBottom: '5px'}}>
                                            <FontAwesomeIcon icon={faVideo} style={{marginRight: '8px', color: 'var(--text-color)'}}/>
                                            {cam.camPosition}
                                        </div>

                                        <div style={{background: 'var(--surface-border)', width: '96%', aspectRatio: '16/9', margin: '0 auto'}}>
                                            {requestedUploads?.[cam.channel.toString()] &&
                                                <MultiVideoUploadProgressTracker upload={requestedUploads[cam.channel]} vehicles={vehicles} ref={ref => videosRef.current[cam.channel] = ref}
                                                                                 addVideo={addVideo} pauseMedia={pauseMedia} playMedia={playMedia} mediaSeeked={mediaSeeked}
                                                                                 handleChnClicked={handleChnClicked}/>
                                            }
                                        </div>

                                    </div>
                                }
                            </React.Fragment>

                        }
                    </React.Fragment>
                )}
            </div>


            {!fetchBlockedEndpoints().includes('video-request') &&
                <div style={{marginTop: '10px'}}>
                    {camsAvailable?.length > 0 && camsAvailable.sort((a, b) => order.indexOf(a.camPosition) - order.indexOf(b.camPosition)).map((cam, i) =>
                        <React.Fragment>
                            {requested !== cam.channel ?
                                <button key={cam.channel} className="p-button p-button-sm"
                                        style={{marginRight: '5px'}}
                                        onClick={async () => {
                                            setRequested(cam.channel)
                                            // addRemoveCameraToReq(i);
                                            setTimeout( () => {
                                                handleUploadEventVid(cam.channel);

                                            }, 500);
                                        }}>
                                    Upload {cam.camPosition}
                                </button>
                                :
                                <Message severity="success" text="Video Requested" />
                            }
                        </React.Fragment>
                    )}
                </div>
            }



        </div>
    )
}
export default MultiVideoV2
