import { useState, useEffect } from 'react';

const useIsVisible = (elementRef) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!elementRef?.current) return;

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry?.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        const currentElement = elementRef.current;
        observer.observe(currentElement);

        // Cleanup the observer on unmount or when elementRef changes
        return () => {
            observer.disconnect();
        };
    }, [elementRef]);

    return isVisible;
};

export default useIsVisible;
