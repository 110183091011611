import React, {useEffect, useRef, useState, useContext} from "react";
import './ListVehicles.css';
import mainContext from "../../contexts/mainContext";
import usePersistState from "../../../functions/localStorage/persistState";
import {filterVehicleList} from "../../../functions/vListFilterFunc";
import VehicleList from "./vehicleList";
import VehicleListFilters from "./vehicleListFilters";
import indexContext from "../../contexts/indexContext";
import {findDataByKey} from "../../../functions/db";





const Aside = () => {


    const {notifications, handleShowSplashScreen} = useContext(indexContext)


    const {handleVehicleFilter,handleVehicleFilterParams, vehicleFilterParams, dbReady } = useContext(mainContext)


    const [filteredVehicles, setFilteredVehicles] = useState();

    const [displayType, setDisplayType] = usePersistState('vehicles', 'displayType');
    const [showFilters, setShowFilters] = useState(false)

    const [vListOrder, setVListOrder] = useState('Descending');
    const [vListTypeOrder, setVListTypeOrder] = useState('Registration');
    const [mounted, setMounted] = useState(false);



    let interval = useRef();



    const handleShowFilters = (val) => setShowFilters(val);
    const handleVListTypeOrder = (val) => {
        setVListTypeOrder(val);
        savedSortType.current = val;
    }
    const handleVListOrder = (val) => {
        setVListOrder(val);
        savedSort.current = val;
    }


    // const addChip = (v) => setChips(new Set([...chips, v]));
    const addChip = async (v) => {

        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: new Set([...vehicleFilterParams?.chips, v]),
            fleetsToShow: vehicleFilterParams?.fleetsToShow
        }
        handleVehicleFilterParams(params);

    };
    const handleRemoveChip = async (c) => {

       let chipsNew  = vehicleFilterParams?.chips;
       chipsNew.delete(c)

        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: chipsNew,
            fleetsToShow: vehicleFilterParams?.fleetsToShow
        }
        handleVehicleFilterParams(params);

    };


    const ref = useRef(null)












// search string param not being used for subsequent requests, need to change to ref as callback doesnt work
    const savedParms = useRef(vehicleFilterParams);
    const savedSort = useRef(vListOrder);
    const savedSortType = useRef(vListTypeOrder)


    const refreshList = async () => {
        const initFiltered = await filterVehicleList(savedParms?.current);

        let arr;
        if (savedSortType?.current === 'Registration'){
            arr = await initFiltered?.features?.sort((a, b) => a?.properties?.registration?.localeCompare(b?.properties?.registration));
        } else if (savedSortType?.current === 'Last update'){
            arr = await initFiltered?.features.sort((a,b) => new Date(b?.properties?.lastLocation?.at) - new Date(a?.properties?.lastLocation?.at));
        } else {
            const reduced = await notifications?.reduce((acc, curVal) => acc.concat(curVal?.dn), []);
            arr = await initFiltered?.features.sort((a,b) => reduced.includes(a.properties.dn) ? -1 : reduced.includes(b.properties.dn) ? 1 : 0);
        }

        if (savedSort === 'Ascending'){
            arr?.reverse();
        }

        setFilteredVehicles({type: 'featureCollection', features: arr});
        handleVehicleFilter({type: 'featureCollection', features: arr})
    }



    useEffect(async() => {
      if (mounted){
          savedParms.current = vehicleFilterParams;
          await refreshList();
      }
    }, [vehicleFilterParams]);


    const setMyTimer = () => {
        var toExactMinute = 60000 - (new Date().getTime() % 60000);

        setTimeout(async () => {
            await refreshList()
            interval.current = setInterval(async () => {
                await refreshList()
            }, 60000)
        }, toExactMinute)
    }


    useEffect(async () => {
       if (mounted && dbReady){
           await refreshList();
           clearInterval(interval?.current)
           setMyTimer();
       }

    }, [mounted, dbReady]);


    useEffect(async () => {
        setMounted(true)
        return () => {
            clearInterval(interval?.current);
            setMounted(false);
            setFilteredVehicles(null)
        }
    },[])

    const orderVehicleList = async (order, asc) => {
        if (filteredVehicles){
            let arr = [];


            if (order === 'Registration'){
                arr = await filteredVehicles?.features?.sort((a, b) => a?.properties?.registration?.localeCompare(b?.properties?.registration));
            } else if (order === 'Last update'){
                arr = await filteredVehicles?.features.sort((a,b) => new Date(b?.properties?.lastLocation?.at) - new Date(a?.properties?.lastLocation?.at));
            } else {
                const reduced = await notifications.reduce((acc, curVal) => acc.concat(curVal?.dn), []);
                arr = await filteredVehicles?.features.sort((a,b) => reduced.includes(a.properties.dn) ? -1 : reduced.includes(b.properties.dn) ? 1 : 0);
            }

            if (asc === 'Ascending'){
                arr?.reverse();
            }

            await setFilteredVehicles({type: 'FeatureCollection', features: arr})
        }
    }




    return (

        <div style={{height: '100vh', zIndex: 2, marginTop: '-4px'}} >


            <VehicleListFilters addChip={addChip} orderVehicleList={orderVehicleList} showFilters={showFilters}
                                vListOrder={vListOrder} vListTypeOrder={vListTypeOrder} handleShowFilters={handleShowFilters}
                                handleVListTypeOrder={handleVListTypeOrder} handleVListOrder={handleVListOrder}
                                handleRemoveChip={handleRemoveChip}/>



            <div ref={ref}  className="vListParent" >
                {/*130 from header and footer measures*/}
                {typeof filteredVehicles?.features !== 'undefined' &&
                    <VehicleList addChip={addChip} displayType={displayType} filteredVehicles={filteredVehicles} showFilters={showFilters}/>
                }
            </div>
        </div>
    )
};

export default Aside;
