import circleExclamationPurple from '../../../../svgs/mapIcons/events/circleExclamationPurple.svg';
import circleExclamationTeal from '../../../../svgs/mapIcons/events/circleExclamationTeal.svg';
import circleExclamationPink from '../../../../svgs/mapIcons/events/circleExclamationPink.svg';

import PinAcceleration from '../../../../svgs/mapIcons/pin-acceleration.svg';
import Arrow from '../../../../svgs/mapIcons/mapArrow.png';
import jInProgressArrow from '../../../../svgs/mapIcons/jInProgressArrow.svg';
import jEndedFlag from '../../../../svgs/mapIcons/jEndedFlag.png';
import jStartFlag from '../../../../svgs/mapIcons/jStartFlag.png';
import vIdlingArrow from '../../../../svgs/mapIcons/vIdlingArrow.svg';
import vStoppedMarker from '../../../../svgs/mapIcons/vStoppedMarker.svg';
import licencePlateCluster from '../../../../svgs/mapIcons/licence-plate-cluster.png';
import licencePlate from '../../../../svgs/mapIcons/licence-plate-1.png';
import mapMarker from '../../../../svgs/mapIcons/mapMarker.svg';
import userCircleTeal from '../../../../svgs/mapIcons/events/userCircleTeal.svg';
import userCirclePink from '../../../../svgs/mapIcons/events/userCirclePink.svg';
import userCirclePurple from '../../../../svgs/mapIcons/events/userCirclePurple.svg';
import dashedCircle from '../../../../svgs/mapIcons/dashedCircle.svg';
import shockCircle from '../../../../svgs/mapIcons/shockCircle.svg';
import accelerationCircle from '../../../../svgs/mapIcons/accelerationCircle.svg';
import brakingCircle from '../../../../svgs/mapIcons/brakingCircle.svg';
import collisionCircle from '../../../../svgs/mapIcons/collisionCircle.svg';
import corneringCircle from '../../../../svgs/mapIcons/corneringCircle.svg';
import distractionCircle from '../../../../svgs/mapIcons/distractionCircle.svg';
import fatigueCircle from '../../../../svgs/mapIcons/fatigueCircle.svg';
import laneDepartureCircle from '../../../../svgs/mapIcons/laneDepartureCircle.svg';
import pedestrianCircle from '../../../../svgs/mapIcons/pedestrianCircle.svg';
import phoneCircle from '../../../../svgs/mapIcons/phoneCircle.svg';
import speedingCircle from '../../../../svgs/mapIcons/speedingCircle.svg';
import smokingCircle from '../../../../svgs/mapIcons/smokingCircle.svg';
import tailgatingCircle from '../../../../svgs/mapIcons/tailgatingCircle.svg';

export const purple = [
    // events
    {
        src: userCirclePurple,
        name: 'userCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: accelerationCircle,
        name: 'accelerationCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: brakingCircle,
        name: 'brakingCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: collisionCircle,
        name: 'collisionCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: corneringCircle,
        name: 'corneringCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: distractionCircle,
        name: 'distractionCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: fatigueCircle,
        name: 'fatigueCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: laneDepartureCircle,
        name: 'laneDepartureCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: pedestrianCircle,
        name: 'pedestrianCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: phoneCircle,
        name: 'phoneCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: shockCircle,
        name: 'shockCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: speedingCircle,
        name: 'speedingCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    // {
    //     src: smokingCircle,
    //     name: 'smokingCircle',
    //     width: 40,
    //     height: 40,
    //     sdf: false
    // },
    // {
    //     src: tailgatingCircle,
    //     name: 'tailgatingCircle',
    //     width: 40,
    //     height: 40,
    //     sdf: false
    // },

// selected point
    {
        src: dashedCircle,
        name: 'dashed-circle',
        width: 30,
        height: 30,
        sdf: false
    },
    {
        src: circleExclamationPurple,
        name: 'circle-svg',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: PinAcceleration,
        name: 'pin-acceleration',
        width: 30,
        height: 30,
        sdf: false
    },


    {
        src: mapMarker,
        name: 'mapMarker',
        width: 30,
        height: 40,
        sdf: false
    },

    {
        src: Arrow,
        name: 'lpArrow',
        width: 30,
        height: 30,
        sdf: true
    },

    {
        src: vStoppedMarker,
        name: 'vStoppedMarker',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jStartFlag,
        name: 'jStartFlag',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jEndedFlag,
        name: 'jEndedFlag',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jInProgressArrow,
        name: 'jInProgressArrow',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: vIdlingArrow,
        name: 'vIdlingArrow',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: licencePlateCluster,
        name: 'cluster-marker',
        width: 47,
        height: 37,
        sdf: false
    },

    {
        src: licencePlate,
        name: 'whitePlate',
        width: 100,
        height: 26,
        sdf: false
    }


];

export const teal = [
    // events
    {
        src: userCircleTeal,
        name: 'userCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: accelerationCircle,
        name: 'accelerationCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: brakingCircle,
        name: 'brakingCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: collisionCircle,
        name: 'collisionCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: corneringCircle,
        name: 'corneringCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: distractionCircle,
        name: 'distractionCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: fatigueCircle,
        name: 'fatigueCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: laneDepartureCircle,
        name: 'laneDepartureCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: pedestrianCircle,
        name: 'pedestrianCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: phoneCircle,
        name: 'phoneCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: shockCircle,
        name: 'shockCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: speedingCircle,
        name: 'speedingCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    // {
    //     src: smokingCircle,
    //     name: 'smokingCircle',
    //     width: 40,
    //     height: 40,
    //     sdf: false
    // },
    // {
    //     src: tailgatingCircle,
    //     name: 'tailgatingCircle',
    //     width: 40,
    //     height: 40,
    //     sdf: false
    // },

// selected point
    {
        src: dashedCircle,
        name: 'dashed-circle',
        width: 30,
        height: 30,
        sdf: false
    },
    {
        src: circleExclamationTeal,
        name: 'circle-svg',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: PinAcceleration,
        name: 'pin-acceleration',
        width: 30,
        height: 30,
        sdf: false
    },


    {
        src: mapMarker,
        name: 'mapMarker',
        width: 30,
        height: 40,
        sdf: false
    },

    {
        src: Arrow,
        name: 'lpArrow',
        width: 30,
        height: 30,
        sdf: true
    },

    {
        src: vStoppedMarker,
        name: 'vStoppedMarker',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jStartFlag,
        name: 'jStartFlag',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jEndedFlag,
        name: 'jEndedFlag',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jInProgressArrow,
        name: 'jInProgressArrow',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: vIdlingArrow,
        name: 'vIdlingArrow',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: licencePlateCluster,
        name: 'cluster-marker',
        width: 47,
        height: 37,
        sdf: false
    },

    {
        src: licencePlate,
        name: 'whitePlate',
        width: 100,
        height: 26,
        sdf: false
    }


];

export const pink = [
    // events
    {
        src: userCirclePink,
        name: 'userCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: accelerationCircle,
        name: 'accelerationCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: brakingCircle,
        name: 'brakingCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: collisionCircle,
        name: 'collisionCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: corneringCircle,
        name: 'corneringCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: distractionCircle,
        name: 'distractionCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: fatigueCircle,
        name: 'fatigueCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: laneDepartureCircle,
        name: 'laneDepartureCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: pedestrianCircle,
        name: 'pedestrianCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: phoneCircle,
        name: 'phoneCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: shockCircle,
        name: 'shockCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: speedingCircle,
        name: 'speedingCircle',
        width: 40,
        height: 40,
        sdf: false
    },
    // {
    //     src: smokingCircle,
    //     name: 'smokingCircle',
    //     width: 40,
    //     height: 40,
    //     sdf: false
    // },
    // {
    //     src: tailgatingCircle,
    //     name: 'tailgatingCircle',
    //     width: 40,
    //     height: 40,
    //     sdf: false
    // },

// selected point
    {
        src: dashedCircle,
        name: 'dashed-circle',
        width: 30,
        height: 30,
        sdf: false
    },
    {
        src: circleExclamationPink,
        name: 'circle-svg',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: PinAcceleration,
        name: 'pin-acceleration',
        width: 30,
        height: 30,
        sdf: false
    },


    {
        src: mapMarker,
        name: 'mapMarker',
        width: 30,
        height: 40,
        sdf: false
    },

    {
        src: Arrow,
        name: 'lpArrow',
        width: 30,
        height: 30,
        sdf: true
    },

    {
        src: vStoppedMarker,
        name: 'vStoppedMarker',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jStartFlag,
        name: 'jStartFlag',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jEndedFlag,
        name: 'jEndedFlag',
        width: 40,
        height: 40,
        sdf: false
    },
    {
        src: jInProgressArrow,
        name: 'jInProgressArrow',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: vIdlingArrow,
        name: 'vIdlingArrow',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: licencePlateCluster,
        name: 'cluster-marker',
        width: 47,
        height: 37,
        sdf: false
    },

    {
        src: licencePlate,
        name: 'whitePlate',
        width: 100,
        height: 26,
        sdf: false
    }


];


