import React from 'react';
import {Colours} from "../colours";

export const MflLogo = (props) => {

    return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 95 95" style={{enableBackground:'new 0 0 442.3872375 92.8457031', width:props.size}} xmlSpace="preserve">

                    <g>
                        <polygon style={{fill: 'var(--main-accent-colour)'}} points="8.18 31.25 0 31.25 0 .01 31.24 .01 31.24 8.19 8.18 8.19 8.18 31.25"/>
                        <polygon style={{fill: 'var(--main-accent-colour)'}} points="31.24 85.85 0 85.85 0 54.61 8.18 54.61 8.18 77.68 31.24 77.68 31.24 85.85"/>
                        <polygon style={{fill: 'var(--main-accent-colour)'}} points="85.84 85.85 54.6 85.85 54.6 77.68 77.66 77.68 77.66 54.61 85.84 54.61 85.84 85.85"/>
                        <polygon style={{fill: 'var(--main-accent-colour)'}} points="85.84 31.25 77.66 31.25 77.66 8.19 54.6 8.19 54.6 .01 85.84 .01 85.84 31.25"/>
                        <path style={{fill: 'var(--main-accent-colour)'}} d="M56.42,42.93c0-7.45-6.06-13.5-13.5-13.5s-13.5,6.06-13.5,13.5,6.06,13.5,13.5,13.5,13.5-6.06,13.5-13.5Z"/>
                    </g>


</svg>
    );
}

