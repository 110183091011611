import React, {useContext, useEffect, useState} from 'react';
import {safeParse} from "../../../../../functions/formatting/safeParse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/pro-regular-svg-icons";
import videoUploadingOnline from "../../../../../svgs/deviceOnlineUpload.gif"
import videoUploadingOffline from "../../../../../svgs/deviceOfflineUpload.gif"
import {formatDate, formatTimeOnly, splitStrTime} from "../../../../../functions/formatting/formatDate";
import {API_URL, NOTIFICATIONS_CALLBACK_URL} from "../../../../../Constants";
import postVideoRequest from "../../../../../api/media/postVideoRequest";
import {Button} from "primereact/button";
import VideoBudgetMessage from "../../../requestingMedia/videoBudgetMessage";
import mainContext from "../../../../contexts/mainContext";
import VideoUploadProgressTracker from "../../../requestingMedia/videoUploadProgressTracker";
import {faGrid2, faVideo, faVideoPlus, faDownload, faChartSimple, faGaugeHigh} from "@fortawesome/pro-light-svg-icons";
import {Tooltip} from "primereact/tooltip";
import indexContext from "../../../../contexts/indexContext";
import FetchMediaToken from "../../../../../api/media/fetchMediaToken";
import {fetchDirection} from "../../../../../functions/formatting/fetchDirection";
import {ConfirmPopup} from "primereact/confirmpopup";
import {Checkbox} from "primereact/checkbox";
import mediaUnavailable from "../../../../../svgs/MediaUnavailable.png";
import {fetchBlockedEndpoints} from "../../../../../functions/fetchBlockedEndpoints";
import VideoUploadProgressTrackerV2 from "../../../requestingMedia/uploadProgressTrackerV2";

const SingleVideo = ({camPosArray, deviceDetails, budget, handleView, addVideo, handleVideosUploading, videosUploading,
                         handleSpeed, handleSpeedLimit, speedLimit}) => {


    const {event, vehicles} = useContext(mainContext);
    const {winWidth} = useContext(indexContext);


    const eventVideoReqDurationMs = 20000

    const [videoSelected, setVideoSelected] = useState();
    const [allowUpload, setAllowUpload] = useState(true);
    const [uploadChannelsAllowed, setUploadChannelsAllowed] = useState([]);
    const [showUploadPane, setShowUploadPane] = useState(false);
    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [contentSrc, setContentSrc] = useState();
    const [dataTrackVidStart, setDataTrackVidStart] = useState();
    const [dataPoint, setDataPoint] = useState();
    const [showVideoTelemetry, setShowVideoTelemetry] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [authorised, setAuthorised] = useState(true);




    const timeUpdated = (e) => {
        const data = (event?.eventData[dataTrackVidStart + Math.round(e.target.currentTime)])
        console.log(dataTrackVidStart )
        setDataPoint(data)
        handleSpeed((data?.speed / 100 * 0.621371).toFixed())
        handleSpeedLimit(data?.maxspeed)
    }


    const handleVideoChange = (val) => {
        if (val) {
            val.dn = deviceDetails?.dn
            setShowUploadPane(false)
            setVideoSelected(val);
            const split = val.file.split("_");
            const split2 = split[1].split("-");
            const year = split2[0].substr(0, 4);
            const month = split2[0].substr(4, 2)
            const date = split2[0].substr(6, 2);
            const hour = split2[0].substr(8, 2)
            const mins = split2[0].substr(10, 2)
            const sec = split2[0].substr(12, 2);

            event?.eventData.forEach((ev, index) => {
                if (ev.acquisitionTime === `${year}-${month}-${date}T${hour}:${mins}:${sec}`) {
                    setDataTrackVidStart(index)
                    setDataPoint(event?.eventData[index])
                }
            });
        }
    }





    const handleUploadEventVid = () => {
        const arr = [...camsToReq]
        for (let i = 0; i < arr.length; i++) {
            const cam = arr[i];
            const obj = {
                eventId: event._id,
                dn: event?.dn,
                ch: cam.channel,
                st: formatDate(new Date(event.startTime).getTime() - (eventVideoReqDurationMs/2)),
                et: formatDate(new Date(event.startTime).getTime() + (eventVideoReqDurationMs/2)),
                callback: NOTIFICATIONS_CALLBACK_URL
            }
            postVideoRequest(obj, deviceDetails, i === 0 ? emailChecked : false).then(r => {
                handleVideosUploading(r?.data);
            });
        }

    }


    // const addRemoveCameraToReq= (v) => {
    //     if(camsToReq.has(v)){
    //         camsToReq.delete(v)
    //         setCamsToReq(new Set([...camsToReq]));
    //     } else {
    //         setCamsToReq(new Set([...camsToReq, v]));
    //     }
    // }


    const downloadFile = async () => {
        await refreshSrc();

        const splitStr = videoSelected?.file.split('_');
        const times = splitStr[1].split("-");

        const vehicle = vehicles?.features.filter(v => v?.properties?.dn?.toString() === splitStr[0])[0];
        let camPos;

        event?.vehicleDetails[0].cameras.forEach(cam => {
            if (cam.channel === videoSelected.ch){
                camPos = cam.camPosition
            }
        })

        const str = vehicle.properties.registration + " " +  splitStrTime(times[0]) + " " + camPos;



        const outsideRes = await fetch(contentSrc);

        const blob = await outsideRes.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = str;
        link.click();


    }

    const addRemoveCameraToReq = (v) => {
        if(setHasAllCams()){
            setCamsToReq(new Set([v]))
        } else {
            if(camsToReq.has(v)){
                camsToReq.delete(v)
                setCamsToReq(new Set([...camsToReq]));
            } else {
                setCamsToReq(new Set([...camsToReq, v]));
            }
        }
    }


    const setHasAllCams = () => {
        return JSON.stringify(camsToReq) === JSON.stringify(new Set(uploadChannelsAllowed)) && camsToReq.size === uploadChannelsAllowed?.length
    }

    const addAllCamsToReq = () => {

        if (setHasAllCams()){
            setCamsToReq(new Set([]))

        } else {
            setCamsToReq(new Set(uploadChannelsAllowed))

        }

    }



    useEffect(() => {
        if (event && camPosArray) {
            handleVideoChange(event?.videos?.[0])

            // work out if any free channels exist
            const uploaded = [];

            event?.videos.forEach(vid => {
                uploaded.push(vid.ch.toString())
            })


            let freeChns = false
            let channelsFree = []

            event?.vehicleDetails?.[0]?.cameras.forEach(cam => {
                if(!uploaded.includes(cam.channel.toString()) && cam?.isRec === '1'){
                    freeChns = true
                    channelsFree.push(cam)
                }
            });

            setUploadChannelsAllowed(channelsFree);
            setAllowUpload(freeChns)

        }

        addRemoveCameraToReq(event?.vehicleDetails?.[0]?.cameras.filter(cam => cam.camPosition === 'Front')[0]);



    }, [event, camPosArray])

    const refreshSrc = async () => {

        if (videoSelected){
            handleSpeed((event?.eventData?.[0]?.speed / 100 * 0.621371).toFixed())
            handleSpeedLimit(event?.eventData?.[0]?.maxspeed)
            const token = await FetchMediaToken(videoSelected?.file? videoSelected?.file : videoSelected?.filename);

            if (token !== '401'){
                setContentSrc(API_URL + '/view-video/' + videoSelected?.file + '?mt=' + token?.access_token);
                setShowVideoTelemetry(true);

            } else {
                setAuthorised(false);
                setShowVideoTelemetry(false);
            }



        }
    }

    useEffect(async () => {
        await refreshSrc();
    }, [videoSelected])

    const ConfirmFooter = () => {
        return (
            <div style={{display: 'flex', padding: '0px 1.5rem 1.5rem 1.5rem', justifyContent: 'space-between'}}>
                <button className="p-button p-button-sm p-button-secondary" onClick={() => setShowConfirm(false)}>
                    Close
                </button>
            </div>
        )
    }


    const VideoButtons = () => {
        if(event?.videos.length > 0){
            return (
                <div style={{color: 'rgba(0,0,0,0   .6)', display:'flex', alignItems: 'center'}}>

                    {event?.videos.map((item, index) =>

                        <React.Fragment>
                            {item?.status !== 'cancelled' &&
                                <span key={index} onClick={() => handleVideoChange(item)}
                                      style={{
                                          cursor: 'pointer', color: 'var(--text-color)', fontSize: '1.1em',
                                          fontWeight: videoSelected?.ch === item.ch ? 'bold' : 'normal'
                                      }}>
                                        {camPosArray[item.ch]}

                                    {event?.videos.length > 1 && index !== event?.videos.length - 1 &&
                                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                    }
                        </span>

                            }
                        </React.Fragment>
                    )}

                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <span onClick={() => handleView()}
                          style={{cursor: 'pointer', color: 'var(--text-color)', fontSize: '1.1em'}}>
                        All
                    </span>


                    {winWidth > 800 &&

                        <div style={{flex: 1, textAlign: 'right', color: 'var(--text-color)', alignItems: 'bottom'}}>

                            {allowUpload && !fetchBlockedEndpoints().includes('video-request') &&

                                <React.Fragment>
                                    <FontAwesomeIcon icon={faVideoPlus} style={{marginLeft: '8px', color: 'var(--text-color)', cursor: 'pointer', fontSize: '22px'}}
                                                     onClick={() => {handleView()}} className="uploadMoreBtn" data-pr-position="bottom"/>

                                    <Tooltip target={".uploadMoreBtn"}>Upload more perspectives</Tooltip>
                                </React.Fragment>

                            }




                            <FontAwesomeIcon icon={faGaugeHigh} style={{marginLeft: '8px', color: 'var(--text-color)', cursor: 'pointer', fontSize: '22px'}}
                                             onClick={() => setShowVideoTelemetry(!showVideoTelemetry)} className="telemetryBtn" data-pr-position="bottom"/>

                            <Tooltip target={".telemetryBtn"}>Telemetry video overlay</Tooltip>

                            <FontAwesomeIcon icon={faDownload} style={{marginLeft: '8px', color: 'var(--text-color)', cursor: 'pointer', fontSize: '22px'}}
                                             onClick={async () => {
                                                 setShowConfirm(true);
                                                 await downloadFile();
                                             }} className="downloadBtn" data-pr-position="bottom"/>

                            <Tooltip target={".downloadBtn"}>Download</Tooltip>
                            <ConfirmPopup visible={showConfirm} onHide={() => setShowConfirm(false)} style={{width: '35vw'}}
                                          message="Your video download has started and will show in your browsers download section,
                                          please do not close myfleetlive.ai until the download has completed" footer={ConfirmFooter} />







                            {event?.videos?.length > 1 && winWidth > 800 &&
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faGrid2} onClick={() => handleView()} style={{cursor: 'pointer', fontSize: '22px', marginLeft: '8px'}}
                                                     className={"multiVidTooltip"} data-pr-position="bottom"/>

                                    <Tooltip target={".multiVidTooltip"}>Multi Video playback</Tooltip>
                                </React.Fragment>
                            }



                        </div>

                    }
                </div>
            )
        } else {
            return <div />
        }
    }


    const VideoUploadPane = ({type}) => {


        return (
            <React.Fragment>
                {!fetchBlockedEndpoints().includes('video-request') ?

                    <div style={{textAlign: 'center', width: '350px', margin: '0 auto'}}>
                        {videosUploading?.length < 1 ?
                            <div>
                                {type === 'noVids' ?
                                    <div>
                                        <h4>No videos for event uploaded</h4>
                                        To create a new event and upload video, select the camera view(s) and duration required and click upload.
                                        You can monitor the progress of your upload here or check your notification Inbox at any time.
                                    </div>
                                    :
                                    <div>
                                        <h4>Upload more video perspectives of event</h4>
                                        To upload a video clip, select the camera views and the duration
                                        required then click Request Video. You can monitor progress here or
                                        check your inbox at any time
                                    </div>
                                }

                                <br />

                                {uploadChannelsAllowed?.length > 1 &&
                                    <Button onClick={() => addAllCamsToReq()} style={{marginRight: '5px', marginBottom: '5px'}}
                                            className={setHasAllCams() ?
                                                'p-button' : 'p-button p-button-secondary'}
                                            tooltip={'All cameras'} tooltipOptions={{position: 'bottom'}}>
                                        <span className="p-button-label">All</span>

                                    </Button>
                                }


                                {uploadChannelsAllowed.map((item, index) =>
                                    <Button key={index}
                                            onClick={() => addRemoveCameraToReq(item)}
                                            style={{marginLeft: '5px', marginBottom: '5px'}}
                                            className={camsToReq.has(item) ? 'p-button' : 'p-button p-button-secondary'}
                                            tooltip={'Channel: ' + item.channel} tooltipOptions={{position: 'bottom'}}>
                                        {item.camPosition}
                                    </Button>
                                )}



                                <br /><br />


                                <div>
                                    <span style={{marginRight: '10px'}}>Notify me by email when complete</span>
                                    <Checkbox onChange={e => setEmailChecked(e.checked)} checked={emailChecked}></Checkbox>
                                </div>





                                <div style={{marginBottom: '15px'}}>
                                    <h4>Upload summary:</h4>
                                    Start time: {formatTimeOnly(new Date(event.startTime).getTime() - (eventVideoReqDurationMs/2))}<br />
                                    End time: {formatTimeOnly(new Date(event.startTime).getTime() + (eventVideoReqDurationMs/2))} <br />
                                    Duration: {eventVideoReqDurationMs/1000} Seconds
                                </div>

                                <button className="p-button" onClick={() => {handleUploadEventVid()}} disabled={(budget?.totalFleetBudget - budget?.usedSeconds) < (eventVideoReqDurationMs/1000)}>
                                    <FontAwesomeIcon icon={faCloudArrowUp} />
                                    &nbsp;Upload video
                                </button>

                                <br />
                                <br />
                                <VideoBudgetMessage budget={budget} camsToReq={camsToReq} duration={eventVideoReqDurationMs/1000} />


                            </div>
                            :

                            <React.Fragment>
                                {/*<button onClick={() => resetForm()} className="p-button" style={{marginBottom: '15px'}}>*/}
                                {/*    Back to upload options*/}
                                {/*</button>*/}


                                <VideoUploadProgressTrackerV2 upload={videosUploading?.[0]?.data}  page="eventModal"/>
                            </React.Fragment>
                        }


                    </div>

                :
                    <div />
                }
            </React.Fragment>


        )
    }

    const ShowUploadingImage = () => {

        const [onlineStatus, setOnlineStatus] = useState();

        useEffect(() => {
            setOnlineStatus(vehicles.features.filter(item => item.properties.dn === event.dn))
        }, [])


        return (
            <div>

                {onlineStatus?.[0]?.properties?.deviceStatus === 'online' ?
                    <img src={videoUploadingOnline} style={{width: '100%', maxHeight: '45vh', margin: '0 auto' }} />
                    :
                    <img src={videoUploadingOffline} style={{width: '100%', maxHeight: '45vh', margin: '0 auto' }} />
                }

            </div>


        )
    }


    return (
        <div style={{height: '46vh'}}>
            {!safeParse(event?.videos) || safeParse(event?.videos)?.length < 1  ?
                <React.Fragment>
                    {videosUploading?.length < 1 ?
                        <VideoUploadPane type="noVids" />
                    :
                        <div style={{margin: '0 auto', textAlign: 'center'}}>
                            <VideoUploadProgressTrackerV2 upload={videosUploading[0]}  page="trackModal"/>
                        </div>
                    }
                </React.Fragment>
                :
                <div>
                    <VideoButtons />
                    <br />
                    {showUploadPane ?
                        <VideoUploadPane type="vids" />
                        :
                        <div>
                            {videoSelected?.status === 'uploaded' ?

                                <div style={{position: 'relative'}}>

                                    {authorised ?
                                        <video style={{width: '100%'}} onTimeUpdate={(e) => timeUpdated(e)}
                                               controls controlsList="nodownload" src={contentSrc} onError={refreshSrc}></video>
                                    :
                                        <img src={mediaUnavailable} style={{width: '100%'}} />

                                    }



                                    {dataPoint && showVideoTelemetry && winWidth > 800 &&
                                        <React.Fragment>
                                            <div style={{display:'flex', alignItems: 'bottom', justifyContent: 'center', gap: '5px', height: '110px',
                                                width: speedLimit ? '200px' : '150px', padding: '5px',
                                                position: 'absolute', bottom: 80, left:15, background: 'rgba(0,0,0,0.2)', borderRadius: '3px'}}>

                                                <div style={{textAlign:'center', color: 'white', position: 'relative'}}>
                                                    <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Heading</div>

                                                    <div style={{transform: `rotate(${dataPoint?.direction}deg)`,
                                                        height: '70px', width: '70px', border: '3px solid gray', borderRadius: '50%'}}>

                                                        <div style={{position:'absolute', top:-5, left:18, width:'10px', height:'10px',
                                                            background:'white', borderRadius:'50%'}}/>


                                                        <div style={{fontSize: '12px',position: 'fixed', top: '50%', left: '46%', transform: `translate(-50%, -50%) rotate(-${dataPoint?.direction}deg)`, textAlign:'center'}}>
                                                            {fetchDirection(dataPoint?.direction)}
                                                            <br />
                                                            {dataPoint?.direction}&deg;
                                                            <br />
                                                            {(dataPoint?.speed / 100 * 0.621371).toFixed()}&nbsp;mph
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{width: '50px', position: 'relative', textAlign:'center', color: 'white'}}>
                                                    <b>GForce</b>
                                                    <div style={{marginTop: '10px',background: (dataPoint?.gSensorImpact/100).toFixed(1) >= 0.5 ? 'red' : 'white',
                                                        height: `${dataPoint?.gSensorImpact/2 < 60 ? dataPoint?.gSensorImpact/2 : 60}px`, width: '25px',
                                                        transition: 'all 0.8s', bottom:20, left: '25%', position: 'absolute', maxHeight: '100px', borderRadius: '2px 2px 0 0'}} />
                                                    <div style={{position: 'absolute', bottom:5, left: '25%'}}>
                                                        {(dataPoint?.gSensorImpact/100).toFixed(1)}G
                                                    </div>
                                                </div>

                                                {speedLimit &&
                                                    <div style={{width: '50px', position: 'relative', textAlign:'center', color: 'white'}}>
                                                        <b>Limit</b>
                                                        <div style={{height: '40px', width: '40px', border: '4px solid red', borderRadius: '50%',
                                                            position:'absolute', bottom:5, left: 5, lineHeight: '32px', fontSize: '16px'}}>
                                                            {speedLimit}
                                                        </div>
                                                    </div>
                                                }



                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            :
                                <div style={{margin: '0 auto', textAlign: 'center'}}>
                                    <VideoUploadProgressTrackerV2 upload={videoSelected} page="trackModal"/>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default SingleVideo
