import React, {useContext, useEffect, useState} from 'react';
import TimesheetReportTableRow from "./timesheetReportTableRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faLocationDot, faImage, faTimer, faBusinessTime, faSignsPost,
    faRuler, faClock} from "@fortawesome/pro-light-svg-icons";
import {DataScroller} from "primereact/datascroller";
import TimesheetReportSummary from "./timesheetReportSummary";
import {formatDateOnly, formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import {Colours} from "../../../../../colours";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import mainContext from "../../../../contexts/mainContext";
import reportsContext from "../../../../contexts/reportsContext";

const TimesheetReportTable = ({timesheetData, reportToPrint, showKpi}) => {

    const {theVehicle} = useContext(reportsContext)

    const TimesheetRow = (item) => {
        return (
            <React.Fragment key={item.index}>
                <TimesheetReportTableRow index={item.index} item={item} timesheetData={timesheetData} />
            </React.Fragment>
        )
    }

    return (

        <React.Fragment>
            <div style={{height: '100%', width:'100%', paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px'}}
                 className={reportToPrint === 'timesheet' ? "section-to-print" : ''}>


                <div className="hideNormalShowPrint">
                    <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <h1>
                                Timesheet report
                            </h1>
                            <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                <b>Driver:</b>&nbsp;
                                {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                    <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                :
                                    <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                }
                                <br />

                                <b>Date range:</b>&nbsp;
                                {formatDateTimesheetReport(timesheetData?.[0]?.date)}
                                &nbsp;->&nbsp;
                                {formatDateTimesheetReport(timesheetData?.[timesheetData.length -1]?.date)}
                            </div>

                        </div>
                        <div>
                            <MflLogo style={{fill:Colours.logoTextColour}} size='100px' textColor={'black'}/>
                            <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px", marginTop: '25px'}}>
                                <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                <br />
                                <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                            </div>
                        </div>
                    </div>

                    {showKpi &&
                        <TimesheetReportSummary timesheetData={timesheetData} />
                    }

                </div>


                    <div className="hideOnPrint">
                        {showKpi &&
                            <TimesheetReportSummary timesheetData={timesheetData}/>
                        }
                        <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                            <b>Date range:</b>&nbsp;
                            {formatDateTimesheetReport(timesheetData?.[0]?.date)}
                            &nbsp;->&nbsp;
                            {formatDateTimesheetReport(timesheetData?.[timesheetData.length -1]?.date)}
                            <br />
                            <b>Driver:</b>&nbsp;
                            {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                <span>{theVehicle.properties.driver} ({theVehicle.properties.registration})</span>
                                :
                                <span>No driver assigned ({theVehicle.properties.registration})</span>
                            }
                        </div>
                    </div>


                <div style={{display: 'grid', gridTemplateColumns: 'repeat(9, minmax(0, 1fr))',
                    borderBottom: '1px solid var(--surface-border)', height: '30px', marginTop: '12px',
                    pageBreakInside: 'auto', fontWeight: 'bold', textAlign: 'center'}} className="reportTable">
                    <div style={{lineHeight: '30px',paddingLeft: '10px'}}>
                        <FontAwesomeIcon icon={faCalendar} />
                        &nbsp;Date
                    </div>
                    <div style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faClock} />
                        &nbsp;
                        Start time
                    </div>
                    <div  style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        &nbsp;Start place
                    </div>
                    <div  style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faClock} />
                        &nbsp;
                        End time
                    </div>
                    <div  style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        &nbsp;
                        End place
                    </div>

                    <div  style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faSignsPost} />
                        &nbsp;
                        Journeys
                    </div>
                    <div style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faRuler} />
                        &nbsp;
                        Distance
                    </div>
                    <div  style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faTimer} />
                        &nbsp;
                        Driving
                    </div>
                    <div  style={{lineHeight: '30px'}}>
                        <FontAwesomeIcon icon={faBusinessTime} />
                        &nbsp;
                        Shift
                    </div>
                </div>

                {/*TODO CHECK REMOVING scrollHeight="71vh" doesnt break lazy loading*/}
                {/*<DataScroller value={timesheetData} itemTemplate={TimesheetRow} rows={4} inline scrollHeight="71vh"*/}
                {/*              className="notificationScroller" style={{height: '100vh'}}/>*/}


                <div style={{overflowY: 'scroll', height: '57vh'}} className="scroller">
                    {timesheetData.map(item =>  TimesheetRow(item))}
                </div>





            </div>
        </React.Fragment>


    )


}
export default TimesheetReportTable
