import React, {useContext, useEffect, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {formatDateOnly, formatDateTimesheetReport, fullDurationFromSeconds} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";
import LoadingSpinner from "../../../loadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import indexContext from "../../../../contexts/indexContext";
import {faCar, faSignsPost} from "@fortawesome/pro-regular-svg-icons";
import FleetIdlingReportSummary from "../idlingReport/fleet/fleetIdleReportSummary";
import VehicleIdlingReportSummary from "../idlingReport/vehicle/vehicleIdlingReportSummary";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import ReportsParameterSelection from "../reportsParameterSelection";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetIdling from "../../../../../api/postFleetIdling";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";



const IdlingReportOverview = ({dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi}) => {

    const {vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext)
    const {theVehicle, theFleet} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }



    const accumulator = (data, prop) => {
        return data.reduce((totalValue, item) => {
            switch (prop) {
                case 'journeys': return totalValue + item?.journeys?.length;
                case 'startTime':
                    const st = new Date(item?.firstJourney?.start?.at);
                    const stMins = (st.getHours() * 60) + st.getMinutes();
                    return  item?.firstJourney ? totalValue +  stMins : totalValue + 0;
                case 'endTime':
                    const et = new Date(item?.lastJourney?.endTime);
                    const etMins = (et.getHours() * 60) + et.getMinutes();
                    return  item?.lastJourney ? totalValue +  etMins : totalValue + 0;
                default : return totalValue + item?.[prop];
            }
        }, 0);
    }

    const generateReportData = async () => {

        const data = {
            fleetId: theFleet?.fleetId,
            startDate: formatDateOnly(dateRange?.[0]).split('-').reverse().join('-'),
            endDate: formatDateOnly(dateRange?.[1]).split('-').reverse().join('-')
        }

        const r = await postFleetIdling(data);

        let distance = 0;
        let idle = 0;
        let journeys = 0;
        let duration = 0;
        let trueIdling = 0;

        let reportData = {};


        r?.forEach(v => {
            distance += v?.distance;
            journeys += v?.journeyCount
            idle += v?.idling;
            duration += v?.durationSeconds
            trueIdling += v?.trueIdling;
        });

        r.sort(function(a, b){return b.idling-a.idling});

        setActivityData(r);

        console.log(reportData)

        const total = {
            journeys,
            distance,
            idle,
            duration,
            trueIdling
        }

        const cache = {
            actData: reportData,
            totals: total,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }

        setActTotals(total);

        localStorage.setItem('idlingReportOverview', JSON.stringify(cache));
        handleReportLoading(false);

    }

    useEffect(async () => {

        if (localStorage.getItem('idlingReportOverview')){
            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('idlingReportOverview'));


            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){


                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }
            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }




    }, [dateRange, theVehicle, theFleet, generateReport]);



    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            {activityData?.length > 0 ?
                                <div style={{
                                    height: '100%',
                                    width: '100%',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    paddingTop: '20px'
                                }}
                                     className={reportToPrint === 'idlingOverview' ? "section-to-print" : ''}>
                                    <div className="hideNormalShowPrint">
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <h1>
                                                    Idling Overview report
                                                </h1>
                                                {theVehicle &&
                                                    <div style={{
                                                        fontSize: "1.3em",
                                                        lineHeight: "25px",
                                                        padding: "20px 0px 20px 0px"
                                                    }}>
                                                        <b>Driver:</b>&nbsp;
                                                        {(theVehicle?.properties?.driver && theVehicle?.properties.driver.length > 0) ?
                                                            <span>{theVehicle.properties.driver} ({theVehicle.properties.registration})</span>
                                                            :
                                                            <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                                        }
                                                        <br/>

                                                        <b>Date range:</b>&nbsp;
                                                        {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                        &nbsp;->&nbsp;
                                                        {formatDateTimesheetReport(activityData?.[activityData.length - 1]?.date)}
                                                    </div>
                                                }

                                                {theFleet &&
                                                    <div style={{
                                                        fontSize: "1.3em",
                                                        lineHeight: "25px",
                                                        padding: "20px 0px 20px 0px"
                                                    }}>
                                                        <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                        <br/>

                                                        <b>Date range:</b>&nbsp;
                                                        {formatDateTimesheetReport(dateRange?.[0])}
                                                        &nbsp;->&nbsp;
                                                        {formatDateTimesheetReport(dateRange?.[1])}
                                                    </div>
                                                }

                                            </div>
                                            <div>
                                                <MflLogo style={{fill:Colours.logoTextColour}} size='100px' textColor={'black'}/>

                                                <div style={{
                                                    marginTop: '25px',
                                                    fontSize: "1.3em",
                                                    lineHeight: "25px",
                                                    padding: "20px 0px 20px 0px"
                                                }}>
                                                    <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                    <br/>
                                                    <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                                </div>
                                            </div>
                                        </div>

                                        {showKpi && theVehicle &&
                                            <VehicleIdlingReportSummary actTotals={actTotals}
                                                                        activityData={activityData}/>
                                        }

                                        {showKpi && theFleet && !theVehicle &&
                                            <FleetIdlingReportSummary actTotals={actTotals}
                                                                      activityData={activityData}/>
                                        }


                                    </div>

                                    <div className="hideOnPrint">
                                        {showKpi && theVehicle &&
                                            <VehicleIdlingReportSummary actTotals={actTotals}
                                                                        activityData={activityData}/>
                                        }

                                        {showKpi && theFleet && !theVehicle &&
                                            <FleetIdlingReportSummary actTotals={actTotals}
                                                                      activityData={activityData}/>
                                        }


                                        {theVehicle &&
                                            <div style={{
                                                fontSize: "1.3em",
                                                lineHeight: "25px",
                                                padding: "20px 0px 20px 0px"
                                            }}>
                                                <b>Date range:</b>&nbsp;
                                                {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                &nbsp;->&nbsp;
                                                {formatDateTimesheetReport(activityData?.[activityData.length - 1]?.date)}
                                                <br/>
                                                <b>Driver:</b>&nbsp;
                                                {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                                    <span>{theVehicle.properties.driver} ({theVehicle.properties.registration})</span>
                                                    :
                                                    <span>No driver assigned ({theVehicle.properties.registration})</span>
                                                }
                                            </div>
                                        }
                                        {theFleet &&
                                            <div style={{
                                                fontSize: "1.3em",
                                                lineHeight: "25px",
                                                padding: "20px 0px 20px 0px"
                                            }}>
                                                <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                <br/>

                                                <b>Date range:</b>&nbsp;
                                                {formatDateTimesheetReport(dateRange?.[0])}
                                                &nbsp;->&nbsp;
                                                {formatDateTimesheetReport(dateRange?.[1])}
                                            </div>
                                        }

                                    </div>


                                    {theFleet &&
                                        <div style={{overflowY: 'scroll', height: '61vh', overflowX: 'hidden'}}
                                             className="scroller">


                                            <div style={{
                                                display: 'grid',
                                                gridTemplateColumns: '20% 20% 20% 20% 20% ',
                                                borderBottom: '1px solid var(--surface-border)',
                                                height: '30px',
                                                marginTop: '12px',
                                                pageBreakInside: 'auto',
                                                fontWeight: 'bold',
                                                textAlign: 'center'
                                            }} className="reportTable">

                                                <div style={{lineHeight: '30px', paddingLeft: '10px'}}>

                                                    <FontAwesomeIcon icon={faCar}/>
                                                    &nbsp;Vehicle
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faSignsPost}/>
                                                    &nbsp;
                                                    Journeys
                                                </div>

                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faRuler}/>
                                                    &nbsp;
                                                    Distance
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faTimer}/>
                                                    &nbsp;
                                                    Average Idle time
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faTimer}/>
                                                    &nbsp;
                                                    Idle time
                                                </div>
                                            </div>


                                            {activityData && activityData?.map(v => (
                                                <div style={{
                                                    display: 'grid', gridTemplateColumns: '20% 20% 20% 20% 20% ',
                                                    borderBottom: '1px solid var(--surface-border)', height: '73px',
                                                    pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
                                                    textAlign: 'center'
                                                }} className="reportTable" key={v?._id}

                                                >
                                                    <div style={{lineHeight: '73px', paddingLeft: '10px  '}}>
                                                        {regFromDn(vehicles, v?._id)}
                                                    </div>

                                                    <div style={{lineHeight: '73px'}}>
                                                        {v?.journeyCount}
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {v?.distance.toFixed(0)}
                                                        &nbsp;miles
                                                    </div>

                                                    <div style={{lineHeight: '73px'}}>
                                                        {fetchUserLevel() === 'superuser' ?
                                                            <React.Fragment>
                                                                i: {(v.idling / v?.journeyCount) } vs ti: {(v.trueIdling / v?.journeyCount) }
                                                            </React.Fragment>
                                                        :
                                                            <React.Fragment>
                                                                {typeof v?.idling === 'number' ? fullDurationFromSeconds(v.idling / v?.journeyCount) : 'N/A'}
                                                            </React.Fragment>
                                                        }
                                                    </div>

                                                    <div style={{lineHeight: '73px'}}>

                                                        {fetchUserLevel() === 'superuser' ?
                                                            <React.Fragment>i: {v?.idling} v ti: {v?.trueIdling}</React.Fragment>
                                                        :
                                                            <React.Fragment> {typeof v?.idling === 'number' ? fullDurationFromSeconds(v.idling) : 'N/A'}</React.Fragment>
                                                        }



                                                    </div>


                                                </div>
                                            ))}


                                        </div>
                                    }


                                </div>

                            :
                                <div style={{fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                                }}>
                                    No Idling data for the given fleet for the given date range
                                </div>

                            }


                        </React.Fragment>
                    }
                </React.Fragment>
            :
                <div style={{padding: '40px 10px 10px 10px', width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{
                        fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                    }}>
                        Please select a fleet and timespan

                        <br/>

                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>

                    </div>
                </div>

            }
        </React.Fragment>
    )
}
export default IdlingReportOverview

