import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import fetchEventsTags from "../../../../../api/events/fetchEventsTags";


export const eventsGridData = async (params, handleDisableDateChange, vehicle, fleet, date, skip, dateSortEvents, scoreSortEvents,
                                     fleets, lastDaysOfEvents) => {
    handleDisableDateChange(true);


    //let data = {eventTags: params.concat(['-journey end', '-health', '-driverId', '-hyperlapse'])}

    let data = {eventTags: params};

    // if (params?.length < 1) {
    //     data.eventTags = data.eventTags.concat(['-Fatigue']);
    // }



    if (data?.eventTags?.includes('superuserQry')){
        data?.eventTags.splice(data?.eventTags?.indexOf('data?.eventTags'), 1)
    } else {
        data.eventTags =  data.eventTags.concat(['-journey end', '-health', '-hyperlapse'])
        if (!data?.eventTags.includes('driverId')) data.eventTags.push('-driverId')
    }






    if (vehicle) {
        data.dn = vehicle?.properties?.dn
    } else if (fleet?.size > 0) {
        data.fleetIds = Array.from(fleet)
    } else if (fetchUserLevel() !== 'superuser') {
        data.fleetIds = fleets.slice(0, 100).reduce((acc, curVal) => acc.concat(curVal.fleetId), [])
    }





    if (date){
        const dateString = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');

        data.startTime = dateString + ' 00:00:00';

        const et = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');

        data.endTime = et + ' 23:59:59';
    } else {

        let today = new Date();
        let start = new Date();
        start.setDate(start.getDate() - lastDaysOfEvents)


        const dateString = [
            start.getFullYear(),
            ('0' + (start.getMonth() + 1)).slice(-2),
            ('0' + start.getDate()).slice(-2)
        ].join('-');

        data.startTime = dateString + ' 00:00:00';

        const et = [
            today.getFullYear(),
            ('0' + (today.getMonth() + 1)).slice(-2),
            ('0' + today.getDate()).slice(-2)
        ].join('-');

        data.endTime = et + ' 23:59:59';
    }

    if (skip > 0) data.skip = skip


    if (scoreSortEvents) data.sort = "score"



    let results = {}


    const filter = (arr) => {
        return arr.filter(obj => {
            let unread = true;
            let email = localStorage.getItem('email')

            if (obj.notificationRead){
                obj.notificationRead.forEach(read => {
                    if (read.email === email) unread = false
                })
            }
            return unread
        });
    }

    if ((data.fleetIds?.length > 0 || data.dn) || fetchUserLevel() === 'superuser'){
        const raw = await fetchEventsTags(data, 'events')
        raw.result = await filter(raw.result)
        results = raw
    }



    // pull out mobile and Mobile Phone
    // indexOf one of them and delete
    // replace other tags as total





    await new Promise(r => setTimeout(r, 500));
    handleDisableDateChange(false);

    return results



}
