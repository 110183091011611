import React, {useContext, useEffect, useRef, useState} from 'react';
import {MflLogo} from "../../svgs/logo-myfleetlive";
import {Colours} from "../../colours";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCompass, faArrowRightFromBracket, faUser, faCarBump, faHeartPulse, faFileChartColumn,
    faFilmSimple, faChevronDown, faChevronUp, faKey, faRuler, faCars, faSpaceStationMoon,
    faMapLocationDot, faCloudArrowUp, faSidebar, faCircleExclamation, faSignsPost, faEnvelope,faMoon, faPalette,
    faBell
} from '@fortawesome/pro-light-svg-icons'
import './menuBar.css'
import {InputSwitch} from "primereact/inputswitch";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import ChangePasswordModal from "./changePasswordModal";
import clearStorage from "../../functions/localStorage/clearStorage";
import indexContext from "../contexts/indexContext";
import {isStarWars} from "../../functions/isStarWars";
import {fetchUserLevel} from "../../functions/fetchUserLevel";
import {fetchBlockedEndpoints} from "../../functions/fetchBlockedEndpoints";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";
import {subscribeToEmails} from "./subscribeToEmails";
import {TOAST_DURATION} from "../../Constants";
import {fetchAdminFleets} from "../../functions/fetchAdminFleets";
import {Dropdown} from "primereact/dropdown";
import {uCaseFirst} from "../../functions/formatting/uCaseFirst";
import postUpdateFleet from "../../api/postUpdateFleet";



const MFLMenuBar = ({}) => {

    const {handlePageToShow, pageToShow, handleShowSidebar, showSidebar,
        handleShortJourneys, showShortJourneys, handleDarkMode, darkMode, winWidth, newNotiCount,
        handleDarkMap, darkMap, fleets, refreshFleets} = useContext(indexContext);


    const [showUserSubmenu, setShowUserSubmenu] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);
    const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
    const [showText, setShowText] = useState();
    const [enableEmailHealthAlerts, setEnableEmailHealthAlerts] = useState(false);
    const [enableEmailAlerts, setEnableEmailAlerts] = useState(false);

    const catMenu = useRef(null);
    const toast = useRef()





    const notiBadge = {
        height: '20px',
        width: '20px',
        borderRadius: '15px',
        background: 'var(--notification-badge)',
        fontSize: '10px',
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: '20px',
        position: 'absolute',
        top:'5%',
        right:'30%'
    }
    const notiBadge2 = {
        height: '20px',
        width: '20px',
        borderRadius: '15px',
        background: 'var(--notification-badge)',
        fontSize: '10px',
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: '20px',
        position: 'absolute',
        top:'15%',
        right:'30%'
    }

    let listItemStyle;
    if (winWidth > 800){
        listItemStyle = {
            width: '100px'
        }
    } else {
        listItemStyle = {}
    }


    let activeLink = window.location.pathname;

    const handleLogout  = () => {
        clearStorage();
    }

    const handleNavigation = (url) => {
        console.log(url)
        window.location = url;
        activeLink = url;
    }

    const handleChangePassword = () => {
        setShowUserSubmenu(false)
        setPasswordChangeModalOpen(true)
    }

    const handleMapButtonClick = () => {
        if (window.location.pathname !== '/main'){
            // handleNavigation('/main');
            handlePageToShow('/map');
        } else {
            handlePageToShow('/map');
        }
    }

    const isLinkSelected = (link) => {

        let style = {
            background: 'var(--surface-ground)',
            color: 'var(--text-color)'

        };

        if (window.location.pathname === '/main'){
            if (pageToShow === link){
                style.background = 'white';
                style.borderBottom = '3px solid var(--main-accent-colour)'
            }
        } else {
            if (window.location.pathname === link){
                style.background = 'var(--gray-300)';
                style.borderBottom = '3px solid var(--main-accent-colour)'

            }
            if (pageToShow === link){
                style.background = 'var(--gray-300)';
                style.borderBottom = '3px solid var(--main-accent-colour)'
            }
        }

        return style
    }



    const closeOpenMenus = (e)=>{
        if(catMenu.current && showUserSubmenu && !catMenu.current.contains(e.target)){
            setShowUserSubmenu(false)
        }
    }

    const toggleEmailHealthAlerts = async () => {

        const onOff = !enableEmailHealthAlerts;
        let title = 'Health alerts enabled';
        let detail = 'Successfully subscribed to fleet health emails'

        if (enableEmailHealthAlerts){
            title = 'Health alerts disabled'
            detail = 'Successfully unsubscribed from fleet health emails'

        }
        await setEnableEmailHealthAlerts(onOff)
        await subscribeToEmails(fleets, localStorage.getItem('email'), 'health', onOff);
        toast?.current?.show({ severity: 'success', summary: title, detail: detail, life: TOAST_DURATION });
        // refresh fleets
        await refreshFleets();
    }

    const toggleEmailAlerts = async () => {

        const onOff = !enableEmailAlerts;
        let title = 'Alerts enabled';
        let detail = 'Successfully subscribed to fleet emails'

        if (enableEmailAlerts){
            title = 'Alerts disabled'
            detail = 'Successfully unsubscribed from fleet emails'
        }
        await setEnableEmailAlerts(onOff)
        await subscribeToEmails(fleets, localStorage.getItem('email'), 'alerts', onOff);
        toast?.current?.show({ severity: 'success', summary: title, detail: detail, life: TOAST_DURATION });
        await refreshFleets();
    }


    const ThemeTemplate = ({item}) => {
        let colour;
        let text = 'white';

        let themes = [];
        let maps = [];

        let darkMapStr = darkMap ? 'darkMap' : 'lightMap'


        switch (item) {
            case 'darkTeal': colour = '#0FC3CF';text= 'black';themes.push(item);break;
            case 'lightTeal': colour = '#3FE6E6';themes.push(item);break;
            case 'dark': colour = 'rgb(115, 24, 236)';themes.push(item);break;
            case 'light': colour = 'rgb(115, 24, 236)';themes.push(item);break;
            case 'darkPink': colour = '#E4277B';themes.push(item);break;
            case 'lightPink': colour = '#ec4899';themes.push(item);break;
            case 'darkMap': colour = 'black';maps.push(item);break
            case 'lightMap': colour = 'white';text= 'black';maps.push(item);break
        }

        let style = {color:text,width: '12px', height: '12px', background: colour, display: 'inline-block', border: 'solid 1px black',
            marginRight: '5px', borderRadius: '2px', cursor: 'pointer', paddingTop:'0px', lineHeight: '10px', textAlign:'center'}



        return (
            <React.Fragment>
                <div style={style}
                     onClick={() => {

                         if (themes.includes(item)){
                             handleDarkMode(item)
                         } else {
                             handleDarkMap()
                         }
                     }}
                     className={item + 'selector'} data-pr-position="left">
                    {themes.includes(item) && darkMode === item && <React.Fragment>x</React.Fragment>}
                    {maps.includes(item) && darkMapStr === item && <React.Fragment>x</React.Fragment>}
                </div>
                <Tooltip target={"."+item+'selector'}>
                    {uCaseFirst(item.replace(/([a-z0-9])([A-Z])/g, '$1 $2'))}
                    {themes.includes(item) && darkMode === item && <React.Fragment> - selected</React.Fragment>}
                    {maps.includes(item) && darkMapStr === item && <React.Fragment> - selected</React.Fragment>}
                </Tooltip>

            </React.Fragment>
        )
    }

    useEffect(async () => {
        if (fleets?.length > 0){
            if (fleets?.[0]?.emailList?.length > 0){

                for (let i = 0; i < fleets?.[0]?.emailList?.length; i++) {

                    if (fleets?.[0]?.emailList[i]?.email === localStorage.getItem('email')){
                        if (fleets?.[0]?.emailList[i]?.list === 'health'){
                            await setEnableEmailHealthAlerts(true)
                        }
                        if (fleets?.[0]?.emailList[i]?.list === 'alerts'){
                            await setEnableEmailAlerts(true)
                        }
                    }
                }
            }
        }
    }, [fleets])


    document.addEventListener('mousedown',closeOpenMenus);



    const removeStaffEmails = async () => {
        for (let i = 0; i < fleets?.length; i++) {
            let fleet = fleets[i]
            if (fleet?.emailList?.length >= 1){
                const ogLen = fleet?.emailList?.length;
                let emailArr = [];

                let emails = ['api@myfleetlive.co.uk', 'graham.plummer@fleetfocus.co.uk', 'mia.foster-smith@fleetfocus.co.uk', 'kjell.anderton@fleetfocus.co.uk',
                'reece.harris@fleetfocus.co.uk', 'sarah@super'];

                fleet?.emailList?.forEach(item => {
                    if (!emails?.includes(item?.email)){
                        emailArr?.push(item);
                    }
                })

                fleet.emailList = emailArr;

                if (fleet?.emailList?.length !== ogLen){
                    await postUpdateFleet(fleet);
                    await new Promise(r => setTimeout(r, 100));
                }

            }

        }
    }












    return (
        <section className="nav-bar noselect">
            <div className="nav-container">
                <div className="brand">
                    <React.Fragment>
                        <MflLogo size='130px' textColor={'var(--text-color)'} iconColor={'var(--main-accent-colour)'} />
                    </React.Fragment>
                </div>
                <nav>
                    <div className="nav-mobile">
                        <a id="nav-toggle" onClick={() => {setMenuToggle(!menuToggle)}} className={menuToggle ? 'active' : ''}>
                            <span></span>
                        </a>
                    </div>
                    <ul className="nav-list" style={{display: menuToggle ? 'block' : 'none'}}>

                        <Tooltip target=".mapButton">View the map and live tracking of vehicles</Tooltip>


                        <li onMouseEnter={() => setShowText('map')} onMouseLeave={() => setShowText(null)}
                            style={listItemStyle} className="mapButton" data-pr-showDelay={800}>
                            <a onClick={() => {setMenuToggle(!menuToggle); handleMapButtonClick();}} style={isLinkSelected('/map')}>
                                {(showText === 'map' || winWidth < 800) ?
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={faMapLocationDot} size="2x" style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                        <br />
                                        Map
                                    </React.Fragment>
                                    :
                                    <FontAwesomeIcon icon={faMapLocationDot} size="2x" style={{marginBottom: '20px'}}/>
                                }




                            </a>
                        </li>


                        {!fetchBlockedEndpoints().includes('view-snapshot') &&
                            <React.Fragment>
                                <Tooltip target=".eventsButton">View driving events for vehicles</Tooltip>

                                <li onMouseEnter={() => setShowText('events')} onMouseLeave={() => setShowText(null)}
                                    style={listItemStyle}  className="eventsButton" data-pr-showDelay={800}>
                                    <a onClick={() => {
                                        setMenuToggle(!menuToggle);
                                        handlePageToShow('/events')
                                    }} style={isLinkSelected('/events')}>
                                        {(showText === 'events' || winWidth < 800) ?
                                            <React.Fragment>
                                                <FontAwesomeIcon icon={faCircleExclamation} size="2x"
                                                                 style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                                <br/>
                                                Events
                                            </React.Fragment>
                                            :
                                            <FontAwesomeIcon icon={faCircleExclamation} size="2x"
                                                             style={{marginBottom: '20px'}}/>

                                        }
                                    </a>
                                </li>
                            </React.Fragment>

                        }

                        {!fetchBlockedEndpoints().includes('view-video') &&

                            <React.Fragment>

                                <Tooltip target=".mediaButton">View uploaded media</Tooltip>


                                <li onMouseEnter={() => setShowText('media')} onMouseLeave={() => setShowText(null)}
                                    style={listItemStyle} className="mediaButton" data-pr-showDelay={800}>
                                    <a onClick={() => {setMenuToggle(!menuToggle); handlePageToShow('/media')}} style={isLinkSelected('/media')}>
                                        {(showText === 'media' || winWidth < 800) ?
                                            <React.Fragment>
                                                <FontAwesomeIcon icon={faCloudArrowUp} size="2x" style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                                <br />
                                                Uploads
                                            </React.Fragment>
                                            :
                                            <FontAwesomeIcon icon={faCloudArrowUp} size="2x" style={{marginBottom: '20px'}}/>
                                        }
                                    </a>
                                </li>
                            </React.Fragment>



                        }


                        {winWidth > 800 &&
                            <React.Fragment>
                                <Tooltip target=".placesButton">View and edit places of interest</Tooltip>
                                <li onMouseEnter={() => setShowText('places')} onMouseLeave={() => setShowText(null)}
                                    style={listItemStyle} className="placesButton" data-pr-showDelay={800}>
                                    <a onClick={() => {setMenuToggle(!menuToggle); handlePageToShow('/places')}} style={isLinkSelected('/places')}>
                                        {(showText === 'places' || winWidth < 800) ?
                                            <React.Fragment>
                                                <FontAwesomeIcon icon={faSignsPost} size="2x" style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                                <br />
                                                Places
                                            </React.Fragment>
                        :
                                            <FontAwesomeIcon icon={faSignsPost} size="2x" style={{marginBottom: '20px'}}/>
                                        }
                                    </a>
                                </li>
                            </React.Fragment>
                        }


                        {winWidth > 800 && (fetchUserLevel() === 'superuser' || fetchUserLevel() === 'mflGroup' || fetchAdminFleets()?.length > 0 ) &&


                            <React.Fragment>
                                <Tooltip target=".reportsButton">View reports</Tooltip>


                                <li onMouseEnter={() => setShowText('reports')} onMouseLeave={() => setShowText(null)}
                                    style={listItemStyle} className="reportsButton" data-pr-showDelay={800}>
                                    <a onClick={() => {setMenuToggle(!menuToggle); handlePageToShow('/reports')}} style={isLinkSelected('/reports')}>
                                        {(showText === 'reports' || winWidth < 800) ?
                                            <React.Fragment>
                                                <FontAwesomeIcon icon={faFileChartColumn} size="2x" style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                                <br />
                                                Reports
                                            </React.Fragment>
                                            :
                                            <FontAwesomeIcon icon={faFileChartColumn} size="2x" style={{marginBottom: '20px'}}/>
                                        }
                                    </a>
                                </li>
                            </React.Fragment>


                        }


                        {!fetchBlockedEndpoints().includes('view-snapshot') &&

                            <React.Fragment>
                                <Tooltip target=".alertsButton">View system and vehicle alerts</Tooltip>

                                <li onMouseEnter={() => setShowText('notifications')} onMouseLeave={() => setShowText(null)}
                                    style={listItemStyle} className="alertsButton" data-pr-showDelay={800}>
                                    <a onClick={() => {
                                        setMenuToggle(!menuToggle);
                                        handlePageToShow('/notifications')
                                    }} style={isLinkSelected('/notifications')}>
                                        {(showText === 'notifications' || winWidth < 800) ?
                                            <React.Fragment>
                                                <FontAwesomeIcon icon={faBell} size="2x"
                                                                 style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                                {newNotiCount > 0 &&
                                                    <div style={notiBadge}>{newNotiCount}</div>
                                                }
                                                <br/>
                                                Alerts
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <FontAwesomeIcon icon={faBell} size="2x" style={{marginBottom: '20px'}}/>
                                                {newNotiCount > 0 &&
                                                    <div
                                                        style={notiBadge2}>{newNotiCount === 100 ? '100+' : newNotiCount}</div>
                                                }
                                            </React.Fragment>

                                        }
                                    </a>
                                </li>
                            </React.Fragment>



                        }

                        <li ref={catMenu} onMouseEnter={() => setShowText('user')} onMouseLeave={() => setShowText(null)}
                            style={listItemStyle}>
                            <a onClick={() => {setShowUserSubmenu(!showUserSubmenu)}}>

                                {(showText === 'user' || winWidth < 800) ?
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={faUser} size="2x" style={{marginTop: '-10px', marginBottom: '10px'}}/>
                                        <br />
                                        User&nbsp;<FontAwesomeIcon icon={showUserSubmenu ? faChevronUp : faChevronDown} />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={faUser} size="2x" style={{marginBottom: '20px'}}/>
                                    </React.Fragment>
                                }
                            </a>
                            <div style={{overflowY: 'scroll'}}>
                                <ul className="nav-dropdown" style={{ display: showUserSubmenu ? 'block': 'none'}}>







                                    <li>
                                        <span style={{textAlign:'left', paddingLeft:'6px', fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'start'}}>
                                            {/*<FontAwesomeIcon icon={faSidebar} size="xl" style={{display:'inline-block', float:'left', marginRight:'5px'}}/>*/}
                                            <FontAwesomeIcon icon={faPalette} size="xl" style={{display:'inline-block', float:'left', marginRight:'5px'}}/>

                                            <div style={{display:'inline-block', marginLeft:'8px', flex:1}}>
                                               Theme
                                            </div>


                                           <div style={{display: 'flex', flexWrap: 'wrap'}}>

                                               {['dark', 'light', 'darkTeal', 'lightTeal', 'darkPink', 'lightPink'].map(i =>(
                                                   <ThemeTemplate item={i} key={i}/>
                                               ))}



                                               {/*<Dropdown options={['dark', 'light', 'darkTeal', 'lightTeal', 'darkPink', 'lightPink']} onChange={e => handleDarkMode(e.value)}*/}
                                               {/*          value={darkMode} valueTemplate={themeTemplate} itemTemplate={themeTemplate} />*/}
                                           </div>

                                            {/*<InputSwitch checked={darkMode} onChange={() => {handleDarkMode('teal');}}*/}
                                            {/*             style={{display:'inline-block', float:'right', marginRight:'10px'}}/>*/}

                                        </span>
                                    </li>
                                    {fetchUserLevel() === 'superuser' &&
                                        <li>
                                            <span style={{
                                                textAlign: 'left',
                                                paddingLeft: '6px',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'start'
                                            }}>
                                                <FontAwesomeIcon icon={faMapLocationDot} size="xl"
                                                                 style={{
                                                                     display: 'inline-block',
                                                                     float: 'left',
                                                                     marginRight: '5px'
                                                                 }}/>

                                                <div style={{display: 'inline-block', marginLeft: '5px', flex: 1}}>
                                                  Map
                                                </div>

                                                {/*           <InputSwitch checked={darkMap} onChange={handleDarkMap}
                                                             style={{
                                                                 display: 'inline-block',
                                                                 float: 'right',
                                                                 marginRight: '10px'
                                                             }}/>
                                                */}

                                                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                                              <ThemeTemplate item={'darkMap'} />
                                              <ThemeTemplate item={'lightMap'} />
                                                    {/*<Dropdown options={['dark', 'light', 'darkTeal', 'lightTeal', 'darkPink', 'lightPink']} onChange={e => handleDarkMode(e.value)}*/}
                                                    {/*          value={darkMode} valueTemplate={themeTemplate} itemTemplate={themeTemplate} />*/}
                                           </div>


                                            </span>
                                        </li>
                                    }

                                    <li>
                                    <span style={{
                                        textAlign: 'left',
                                        paddingLeft: '6px',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>

                                        <FontAwesomeIcon icon={faCars} size="xl"
                                                         style={{
                                                             display: 'inline-block',
                                                             float: 'left',
                                                             marginRight: '5px'
                                                         }}/>

                                        <div style={{
                                            display: 'inline-block',
                                            marginLeft: '3px',
                                            flex: 1
                                        }}>Vehicle list</div>

                                        <InputSwitch checked={showSidebar} onChange={(e) => handleShowSidebar(e.value)}
                                                     style={{
                                                         display: 'inline-block',
                                                         float: 'right',
                                                         marginRight: '10px'
                                                     }}/>

                                    </span>

                                    </li>

                                    {fetchUserLevel() === 'superuser' &&
                                        <li>
                                            <span style={{
                                                textAlign: 'left',
                                                paddingLeft: '6px',
                                                fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'start'}}>
                                                <FontAwesomeIcon icon={faRuler} size="xl"
                                                                 style={{display:'inline-block', float:'left', marginRight:'5px'}}/>

                                                <div style={{display:'inline-block', marginLeft:'8px', flex:1}}>Short journeys</div>

                                                <InputSwitch checked={showShortJourneys} onChange={handleShortJourneys}
                                                             style={{display:'inline-block', float:'right', marginRight:'10px'}}/>

                                            </span>
                                        </li>
                                    }







                                    {fetchUserLevel() === 'fleet' &&
                                        <li>
                                            <span style={{textAlign:'left', paddingLeft:'6px', fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'start'}}>
                                                <FontAwesomeIcon icon={faHeartPulse} size="xl"
                                                                 style={{display:'inline-block', float:'left', marginRight:'5px'}}/>

                                                <div style={{display:'inline-block', marginLeft:'8px', flex:1}}>Health Alerts</div>

                                                <InputSwitch checked={enableEmailHealthAlerts} onChange={toggleEmailHealthAlerts}
                                                             style={{display:'inline-block', float:'right', marginRight:'10px'}}/>

                                            </span>
                                        </li>
                                    }

                                    {fetchUserLevel() === 'fleet' &&

                                        <li>
                                            <span style={{textAlign:'left', paddingLeft:'6px', fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'start'}}>
                                                <FontAwesomeIcon icon={faCircleExclamation} size="xl"
                                                                 style={{display:'inline-block', float:'left', marginRight:'5px'}}/>

                                                <div style={{display:'inline-block', marginLeft:'8px', flex:1}}>Event Alerts</div>

                                                <InputSwitch checked={enableEmailAlerts} onChange={toggleEmailAlerts}
                                                             style={{display:'inline-block', float:'right', marginRight:'10px'}}/>

                                            </span>
                                        </li>
                                    }












                                    <li>
                                    <span style={{textAlign:'left', paddingLeft:'6px', display:'flex', alignItems: 'center', justifyContent: 'start'}} className="logout"
                                          onClick={handleChangePassword}>
                                        <FontAwesomeIcon icon={faKey} size="xl"
                                                         style={{display:'inline-block', float:'left', marginRight:'5px'}}/>
                                       <div style={{display:'inline-block', marginLeft:'8px', flex:1}}>Change Password</div>

                                    </span>
                                    </li>

                                    <li>
                                    <span style={{textAlign:'left', paddingLeft:'6px'}} className="logout" onClick={handleLogout}>
                                        <FontAwesomeIcon icon={faArrowRightFromBracket} size="xl"
                                                         style={{display:'inline-block', float:'left', marginRight:'5px'}}/>
                                        <div style={{display:'inline-block', marginLeft:'8px'}}>Logout</div>

                                    </span>
                                    </li>
                                </ul>

                            </div>
                        </li>
                    </ul>
                </nav>
            </div>



            <Dialog header="Changing Password" visible={passwordChangeModalOpen} style={{ width: winWidth >= 800 ? '50vw' : '90vw' }}
                    onHide={() => setPasswordChangeModalOpen(false)}>
                <ChangePasswordModal />
            </Dialog>

            <Toast ref={toast} appendTo={null} position="bottom-right"/>

        </section>
    );
}

export default MFLMenuBar;
