import {API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";



const fetchPois = (data) => {

    const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(data)
    };

    return fetch(API_URL + '/list-points-of-interest', requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }

            if (response.status === 401) return [];
            return response.json()
        })
        .then((data) => {
            return Promise.resolve(data);
        }).catch(err=>{
            console.log(err)});

}


export default fetchPois;
